import React, { useEffect, useState } from 'react'
import { ValueChainAPI } from '../apis';
import DisplayStats from 'components/Statistics/DisplayStats';
import AdiTable from 'components/AdiTable';
import ItemModal from './ItemModal';
import { formatTitle, initialCellValue, initialDatellValue } from 'utilities/Functions/FormatFunctions';
import { TABLE_CELL_CONFIG } from 'utilities/CONSTANTS';
import { getBizCode } from 'utilities/Functions/GlobalHelperFunctions';
import initDB from '../Utils/db'
const PACK_TYPES = {
  PACK: 'Pack',
  CASE: 'Case',
  PALLET: 'Pallet',
  BATCH: 'Batch',
  ANIMAL: 'Animal',
  LIVESTOCK: 'Livestock',
  CARCASE: 'Carcase',
  PRIMARY_CUT: 'Primary Cut',
  CARTONS: 'Carton'
}

const AllItems = () => {
  const [tableData, setTableData] = useState<any>([]);
  const [isDataLoading, setIsDataLoading] = React.useState<boolean>(false);

  const [openModal, setOpenModal] = useState<any>({
    open: false,
    data: null,
  });
  const [searchValues, setSearchValues] = useState<any>({
    itemId: '',
    dateRange: []
  });
  const [statsInfo, setStatsInfo] = useState<any>({
    packs: 0,
    cases: 0,
    pallets: 0,
  });

  useEffect(() => {
    getAllItems()
  }, []);


  // const getAllItems = async () => {
  //   // let allDataCache: any;
  //   try {
  //     // const db = await initDB();
  //     // const tx = db.transaction('records', 'readonly');
  //     // const store = tx.objectStore('records');
  //     // const allData: any = await store.getAll();
  //     // if (allData.length === 0) {
  //     // Fetch data from API
  //     setIsDataLoading(true)
  //     const response = await ValueChainAPI.getAllItems()
  //     setIsDataLoading(false)

  //     const BIZ_CODE = getBizCode();


  //     const busPackingStep = response?.filter((item: any) => (item?.businessStep === 'packing' || item?.businessStep === 'Packing'))
  //     console.log("busPcakingStep ========> ", busPackingStep);

  //     const statsData = getStatsData(busPackingStep)

  //     console.log("statsData =========> ", statsData);

  //     setStatsInfo(statsData)

  //     // filter by packType,and add key
  //     const updatedData = busPackingStep?.filter(
  //       (item: any) => (BIZ_CODE === "acg" || BIZ_CODE === "acc") ? item?.packType === PACK_TYPES.CARTONS : true)
  //       .map((item: any, index: number) => {
  //         return {
  //           ...item,
  //           key: index + 1,
  //           productName: 'Carcase'
  //         }
  //       })
  //     // const tx = db.transaction('records', 'readwrite');
  //     // const store = tx.objectStore('records');
  //     // for (const record of updatedData) {
  //     //   await store.put(record);
  //     // }

  //     setTableData(updatedData || [])

  //     // }
  //     // else {
  //     //   console.log('okkkkk', allData)
  //     //   setTableData(allData || [])
  //     // }
  //   } catch (e) {
  //     // console.log('Error getting elements...', e)
  //   } finally {
  //     setIsDataLoading(false)
  //   }
  // };


  const getAllItems = async () => {
    try {
      const db = await initDB();
      const tx = db.transaction('records', 'readonly');
      const store = tx.objectStore('records');
      const allData = await store.getAll();

      if (allData.length === 0) {
        // Fetch data from API
        setIsDataLoading(true);
        const response = await ValueChainAPI.getAllItems();
        setIsDataLoading(false);

        const BIZ_CODE = getBizCode();

        const busPackingStep = response?.filter(
          (item: any) => item?.businessStep === 'packing' || item?.businessStep === 'Packing'
        );
        console.log("busPackingStep ========> ", busPackingStep);

        const statsData = getStatsData(busPackingStep);
        console.log("statsData =========> ", statsData);

        setStatsInfo(statsData);

        // Filter by packType, and add key and productName
        const updatedData = busPackingStep?.map((item: any, index: any) => {
          const shouldInclude = (BIZ_CODE === "acg" || BIZ_CODE === "acc") ? item?.packType === PACK_TYPES.CARTONS : true;
          return {
            ...item,
            key: index + 1,
            productName: shouldInclude ? 'Carcase' : item.productName || 'Carcase', // Ensure productName is set
          };
        });

        // Debugging: Check the updated data
        console.log("updatedData before saving to IndexedDB ========> ", updatedData);

        const txWrite = db.transaction('records', 'readwrite');
        const storeWrite = txWrite.objectStore('records');
        for (const record of updatedData) {
          await storeWrite.put(record);
        }

        setTableData(updatedData || []);
      } else {
        console.log('Data retrieved from IndexedDB: ', allData);
        const updatedData = allData?.map((item: any, index: any) => {
          return {
            ...item,
            key: index + 1,
            productName: 'Carcase',
          };
        });

        setTableData(updatedData || []);
      }
    } catch (e) {
      console.error('Error getting elements...', e);
    } finally {
      setIsDataLoading(false);
    }
  };
  const getStatsData = (data: any) => {

    const getTotalQuantity = (palletsData: any) => {
      let totalQuantity = 0;

      palletsData.forEach((item: any) => {
        totalQuantity += parseInt(item.quantity, 10) || 0;
      });

      return totalQuantity;
    };

    const packs = data?.filter((item: any) => item?.packType === PACK_TYPES.PACK)
    const pallets = data?.filter((item: any) => item?.packType === PACK_TYPES.PALLET)
    const cases = pallets?.length > 0 && getTotalQuantity(pallets) || 0;

    return {
      packs: packs?.length || 0,
      cases: cases || 0,
      pallets: pallets?.length || 0,
    }
  };

  const statsData = getBizCode() === "acc" || getBizCode() === "acg" ? [
    {
      text: 'Livestock',
      number: 78
    },
    {
      text: 'Carcase',
      number: 78
    },
    {
      text: 'Primary Cut',
      number: 780
    },
    {
      text: 'Cartons',
      number: 780
    },

  ] : [
    {
      text: 'Products',
      number: 1,
    },
    {
      text: 'Packs Created',
      number: statsInfo?.packs || 0,
    },
    {
      text: 'Cases',
      number: statsInfo?.cases || 0,
    },
    {
      text: 'Pallets',
      number: statsInfo?.pallets || 0,
    }
  ];

  const onSearch = (values?: any) => {
    const searchFields = values ? values : searchValues
    // console.log('onSearch', searchFields)
  }

  const filterInputConfig = [
    {
      type: 'search',
      placeholder: 'Search by Item ID',
      onChange: (e: any) => {
        const value = e.target.value || ''
        const searchFields = { ...searchValues, itemId: value }
        if (!value) {
          onSearch(searchFields)
        }
        setSearchValues(searchFields)
      },
      onSearch: onSearch,
      allowClear: true,
      inputStyle: { width: '300px' }
    },
    {
      type: 'daterange',
      placeholder: 'Select Date Range',
      onChange: (momentObjects: any[], dateString: [string, string]) => {
        // console.log('dateString', momentObjects, dateString)
        const searchFields = {
          ...searchValues,
          dateRange: momentObjects
        }
        setSearchValues(searchFields)
        onSearch(searchFields)
      },
    },
  ];

  const handleRowClick = (record: any) => {
    console.log('handleRowClick record', record)
    setOpenModal({
      ...openModal,
      open: true,
      data: record,
    });
  };

  const onCloseModal = () => {
    setOpenModal({
      ...openModal,
      open: false,
    });
  };
  return (
    <>
      <div style={{ marginBottom: '20px' }}>
        <DisplayStats data={statsData} />
      </div>
      {/* <FilterHeader
        inputsConfig={filterInputConfig}
      /> */}
      <AdiTable
        marginTop='20px'
        loading={isDataLoading}
        fullWidth
        rowKey={(record: any) => record.key}
        onRowClick={(record: any) => handleRowClick(record)}
        tableData={tableData}
        columns={columns}
      />
      <ItemModal
        open={openModal.open}
        onClose={onCloseModal}
        selectedProduct={openModal.data}
      />
    </>
  )
}

export default AllItems

const columns: any = [
  {
    title: formatTitle("Chain ID"),
    dataIndex: "instanceIdentifier",
    key: "instanceIdentifier",
    ...TABLE_CELL_CONFIG,
    sorter: (a: any, b: any) => a.instanceIdentifier - b.instanceIdentifier,
    render: initialCellValue,
  },
  {
    title: formatTitle("Carton No"),
    dataIndex: "packID",
    key: "packID",
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle("SKU"),
    dataIndex: "productSKU",
    key: "productSKU",
    ...TABLE_CELL_CONFIG,
    width: 200,
    render: initialCellValue,
  },
  {
    title: formatTitle("Product Name"),
    dataIndex: "productName",
    key: 'productName',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue
  },
  {
    title: formatTitle("Batch No"),
    dataIndex: "productLot",
    key: "productLot",
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle("Expiry Date"),
    dataIndex: "productExpiry",
    key: "productExpiry",
    ...TABLE_CELL_CONFIG,
    render: initialDatellValue,
  },
  // {
  //   title: formatTitle("Current Event"),
  //   dataIndex: "businessStep",
  //   key: "businessStep",
  //   ...TABLE_CELL_CONFIG,
  //   render: initialCellValue,

  // },
  {
    title: formatTitle("Current Location"),
    dataIndex: "currentbusinessLocation",
    key: "currentbusinessLocation",
    ...TABLE_CELL_CONFIG,
    render: (value: any, record: any) => {
      return record?.currentbusinessLocation?.businessName || initialCellValue('')
    }
  },
];

