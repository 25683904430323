/**
 * Entrance of the program
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './containers/App';

import 'react-phone-number-input/style.css'
import "antd/dist/antd.css";
import './styles/swift.css';
import './styles/hover-min.css';
import './styles/antd.css';
import './styles/main.css';
import './styles/theme-default.css';
import 'react-quill/dist/quill.snow.css';


// ReactDOM.render(
//   <App />
//   ,
//   document.getElementById('root')
// );


import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./microsoftAuthConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Microsoft Authentication for email syncing
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  ReactDOM.render(
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
    ,
    document.getElementById('root')
  );
});

export default null;
