/**
 * @description Relationship Management Page entry, renders the page header and the tabbed page
 * @version 1.0.0
 * @author Bruce Zhu 
 */

import React, { useEffect } from 'react';
import { PageWrapper, Body } from '../../../statics/styles/StyledComponents';
import { isMobile } from 'react-device-detect';
import Header from '../../Headers/DesktopHeader';
import PageTabs from 'components/PageTabs';
import CRMRoutes, { CRM_ROUTES } from './CRMRoutes';
import { useCRMMsal } from './hooks';
import { SyncEmailAPI } from './apis';
import useSyncEmailStore, { SyncEmailStore } from 'zustand-stores/useSyncEmailStore';

const TABS = {
  DASHBOARD: 'Dashboard',
  COMPANY: 'Company',
  CONTACT: 'Contact',
  TASK: 'Task',
};

const tabsData = [
  {
    label: TABS.DASHBOARD,
    key: TABS.DASHBOARD,
    path: CRM_ROUTES.DASHBOARD
  },
  {
    label: TABS.COMPANY,
    key: TABS.COMPANY,
    path: CRM_ROUTES.COMPANY
  },
  {
    label: TABS.CONTACT,
    key: TABS.CONTACT,
    path: CRM_ROUTES.CONTACT
  },
  {
    label: TABS.TASK,
    key: TABS.TASK,
    path: CRM_ROUTES.TASK
  }
]

const storage = {
  getAutoSyncStatus: () => Boolean(sessionStorage.getItem('shouldAutoSyncEmail')),
  setAutoSyncStatus: (status: boolean) => sessionStorage.setItem('shouldAutoSyncEmail', status.toString())
}

const RelationshipManagement: React.FC = () => {
  const shouldAutoSyncEmail: boolean = storage.getAutoSyncStatus()

  const tabs = tabsData.map((item: any) => {
    return {
      text: item.label,
      path: item.path
    };
  });

  const [msalAction] = useCRMMsal()
  const setIsSyncing = useSyncEmailStore((state: SyncEmailStore) => state.setIsSyncing)

  // Auto sync user emails on page load if it has not been synced
  // Only sync once per session
  useEffect(() => {
    if (!shouldAutoSyncEmail) {
      syncEmails()
    }

  }, [shouldAutoSyncEmail])

  const syncEmails = async () => {
    setIsSyncing(true)
    try {
      const token = await msalAction.getSilentToken({ disabledWarning: true })
      if (token) {
        await SyncEmailAPI.syncEmails(token)
        storage.setAutoSyncStatus(true)
      }
    } catch (e) {
      console.log('syncEmails', e);
    } finally {
      setIsSyncing(false)
    }
  }


  return (
    <PageWrapper>
      {!isMobile && <Header pageTitle="Relationship Management" />}
      <Body>
        <PageTabs tabs={tabs} />
        <CRMRoutes />
      </Body>
    </PageWrapper>
  );
};

export default RelationshipManagement;
