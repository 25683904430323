import React, { useEffect, useState, } from 'react'
import { Row, Col, message, } from 'antd'
import Input from 'components/Input'
import SimpleButton from 'components/GlobalButtons/SimpleButton'
import { LogType } from '../../statics/types'
import styled from 'styled-components'
import { useLogFilters } from '../../hooks'
import { SyncOutlined } from '@ant-design/icons';
import { useAuthStore, } from 'zustand-stores';
import { AuthStore } from 'zustand-stores/useAuthStore';


type Props = {
  logType: LogType;
  onBtnClick: () => void;
  disabledDate?: (current: any) => boolean;
  objectId: string;
  object: 'company' | 'contact';
  setFilteredData: Function
  hideBtn?: boolean;
}

/**
 * Header for meeting and call logs
 */
const LogHeader = (props: Props) => {
  const { logType, objectId, object, onBtnClick, setFilteredData, hideBtn } = props;
  const lowLogType = logType.toLowerCase();
  const isCrmReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.crm.isReadOnly)
  const [filtering, setFiltering] = useState(false)

  // custom hook to handle filters
  const [filteredData, setFilters, setConfirmFilter] = useLogFilters(object, objectId, logType)


  const startFilter = () => {
    setConfirmFilter(true)
    setFiltering(true)
  }

  const onSearchTextChange = (e: any) => {
    const value = e.target.value
    setFilters((filters) => ({ ...filters, searchText: value }))
    if (!value) {
      startFilter()

    }
  }

  useEffect(() => {
    if (filteredData.length === 0 && filtering) {
      message.info(`No ${lowLogType}s found`)
    } else {
      setFilteredData(filteredData)
    }

    setFiltering(false)
  }, [filteredData])




  return (
    <HeaderRow >
      <Col style={{ display: 'flex' }}>
        <Input
          placeholder={`Search ${logType}s`}
          style={{ width: '300px' }}
          type='search'
          onChange={onSearchTextChange}
          onSearch={() => startFilter()}
          allowClear
        />
      </Col>
      {
        !isCrmReadOnly &&
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          {!hideBtn &&
            <SimpleButton
              style={{ float: 'right', padding: '4px 20px' }}
              text={`Log ${logType}`}
              onClick={onBtnClick}
            />
          }
        </Col>
      }

    </HeaderRow >
  )
}

export default LogHeader

const HeaderRow = styled(Row)`
  // position: -webkit-sticky;
  // position: sticky;
  // top: 45px;
  // z-index: 10;
  background-color: inherit;
  padding: 20px 0;
  margin-top: -20px !important;
  margin-bottom: -20px !important;
  display: flex;
  justify-content: space-between;
`

const SyncIcon = styled(SyncOutlined)`
  margin-right: 1rem;
  font-size: 15px;
  padding: 5px;

  cursor: ${({ disabled }: { disabled?: boolean }) => disabled ? 'not-allowed !important' : 'pointer'};

  &:hover {
    background-color: #33334d;
    border-radius: 30%;
    filter: brightness(1.2);
  }

  

`