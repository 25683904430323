import { logError } from "utilities/Functions/GlobalHelperFunctions";
import { VALUE_CHAIN_API } from "utilities/AdiApi";
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";

// const getBizCode = () => 'papl'

const ValueChainAPI = {
  getIdsByTrackLevel: async (level: string) => {
    const bizCode = getBizCode()
    try {
      const res = await VALUE_CHAIN_API.get(`${level}/${bizCode}`)
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getAllValueChains: async () => {
    const bizCode = getBizCode()
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain/${bizCode}`)
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getAllItems: async () => {
    const bizCode = getBizCode()
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain_items/${bizCode}`)
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getTrackEvents: async (params: { gtin: string, level: string, refID: string, startDate: string, endDate: string }) => {
    // construct query string
    const query = Object.entries(params).map(([key, value]) => `${key}=${value}`).join('&')

    const bizCode = getBizCode()
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain/${bizCode}?${query}`)
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getAllProducts: async () => {
    const bizCode = getBizCode()
    try {
      const res = await VALUE_CHAIN_API.get(`products/${bizCode}`)
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getValueChainByRefId: async (refId: string) => {
    const bizCode = getBizCode()
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain/${bizCode}?refID=${refId}`)
      return res.data.body[0]
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getSupplyChain: async () => {
    const bizCode = getBizCode()
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain_supply_chain/${bizCode}`)
      return res.data?.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getAllEvents: async () => {
    const bizCode = getBizCode()
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain_events/${bizCode}`)
      return res.data?.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getEpcs: async () => {
    const bizCode = getBizCode()
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain_events/${bizCode}?allEpcs`)
      return res.data?.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getBusinessLocationsByProductLot: async (productLot: string) => {
    const bizCode = getBizCode()
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain_events/${bizCode}?ProductLot=${productLot}`)
      const locations: string[] = res.data.body || []
      return locations
    } catch (e) {
      logError(e)
      throw e
    }

  },
  getTraceSearchResult: async (params: string) => {
    const bizCode = getBizCode()
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain_events/${bizCode}?${params}`)
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getAllDispositionsList: async () => {
    const bizCode = getBizCode()
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain_events/${bizCode}?allDispositions`)
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getAllBusinessLocations: async () => {
    const bizCode = getBizCode()
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain_items/${bizCode}?allBusinessLocations`)
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getAllBusinessStep: async () => {
    const bizCode = getBizCode()
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain_items/${bizCode}?allBizSteps`)
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getAllCTECodes: async () => {
    const bizCode = getBizCode()
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain_items/${bizCode}?allCteCode`)
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getAllReadPoints: async () => {
    const bizCode = getBizCode()
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain_events/${bizCode}?allRedPointIds`)
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getAllBizTransactions: async () => {
    const bizCode = getBizCode()
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain_events/${bizCode}?allBizTransactions`)
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getEventMapSearchResult: async (params: string) => {
    const bizCode = getBizCode()
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain_traceability/${bizCode}?tracking&${params}`)
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getEventsListByCteCode: async (cteCode: string) => {
    const bizCode = getBizCode()
    // const bizCode = 'papl'
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain_items/${bizCode}?cteCode=${cteCode}`)
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
  getEventsById: async (eventId: string) => {
    const bizCode = getBizCode()
    // const bizCode = 'papl'
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain_events/${bizCode}?eventID=${eventId}`)
      return res.data.body
    } catch (e) {
      logError(e)
      throw e
    }
  },
}

export default ValueChainAPI