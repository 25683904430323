
import React, { useEffect, useState } from "react";
import {
  PageWrapper,
  Body,
} from "../../../statics/styles/StyledComponents";
import { isMobile } from "react-device-detect";
import Header from "../../Headers/DesktopHeader";
import PageTabs from "../../../components/PageTabs";
import VCTRoutes, { VCT_ROOT_PATH } from "./VCTRoutes";
import styled, { keyframes } from "styled-components";
import gs1Icon from './statics/images/gs1Icon.png';
import VocabModal from "./components/VocabModal";
import { Tooltip } from "antd";
import epcisVocab from './statics/epcisVocab.json';
import { useResponsive } from "utilities/hooks";
import useVocabModels, { VocabState } from "zustand-stores/useVocabModel";
import { useMediaQuery } from "@material-ui/core";
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";

const TABS = {
  ORIGIN: "Origin",
  SUPPLY_CHAIN: "Supply Chain",
  EVENTS: "Events",
  ITEMS: "Items",
  TRACE: "Trace",
  ALERTS: "Alerts",
  ISSUES: "Issues",
  RECALLS: "Recalls",
  PRODUCT_LIFE: "Product Life",
  COMPLIANCE: "Compliance",
  SCANS: "Engagement",
};


const tabsData = [
  {
    label: TABS.ORIGIN,
    key: TABS.ORIGIN,
    relativeRoute: 'origin'
  },
  {
    label: TABS.SUPPLY_CHAIN,
    key: TABS.SUPPLY_CHAIN,
    relativeRoute: 'supplyChain'
  },
  {
    label: TABS.EVENTS,
    key: TABS.EVENTS,
    relativeRoute: 'events'
  },
  {
    label: TABS.ITEMS,
    key: TABS.ITEMS,
    relativeRoute: 'items'
  },
  {
    label: TABS.TRACE,
    key: TABS.TRACE,
    relativeRoute: 'trace'
  },
  {
    label: TABS.ALERTS,
    key: TABS.ALERTS,
    relativeRoute: 'alerts'
  },
  {
    label: TABS.ISSUES,
    key: TABS.ISSUES,
    relativeRoute: 'issues'
  },
  {
    label: TABS.RECALLS,
    key: TABS.RECALLS,
    relativeRoute: 'recalls'
  },
  {
    label: TABS.PRODUCT_LIFE,
    key: TABS.PRODUCT_LIFE,
    relativeRoute: 'productLife'
  },
  {
    label: TABS.COMPLIANCE,
    key: TABS.COMPLIANCE,
    relativeRoute: 'compliance'
  },
  {
    label: TABS.SCANS,
    key: TABS.SCANS,
    relativeRoute: 'scans'
  }
];

const ProductTracking = () => {

  const screen = useResponsive();
  const largeEnough = screen.useMediaQuery({ query: "(min-width: 1300px)" });

  const { setVocabModal, open, data, closeVocabModal } = useVocabModels((state: VocabState) => state)
  const vocabModal = open;
  const vocabData = data;

  const tabs = tabsData
    .filter((item) => (getBizCode() === "acg" || getBizCode() === "acc") ? item.label !== TABS.PRODUCT_LIFE && item.label !== TABS.SCANS : true)
    .map((item: any) => {
      return {
        text: item.label,
        path: `${VCT_ROOT_PATH}/${item.relativeRoute}`
      };
    });

  return (
    <PageWrapper>
      {!isMobile && (
        <Header
          pageTitle="Value Chain Tracking"
          displayGS1={!largeEnough}
        />
      )}
      <Body>
        {/* <Tooltip title="Click to view Gs1 Vocab">
          <VocabIcon 
          style={{
            display: largeEnough ? "flex" : "none"
          }} 
          onClick={setVocabModal}>
            <img src={gs1Icon} alt="GS1 Icon" />
          </VocabIcon>
        </Tooltip> */}
        <PageTabs tabs={tabs} rowStyle={{ marginBottom: "20px", }} />
        <VCTRoutes />
      </Body>
      <VocabModal
        open={vocabModal}
        tableData={vocabData}
        onClose={closeVocabModal}
      />
    </PageWrapper>
  );
};

export default ProductTracking;

const zoomInOut = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

const VocabIcon = styled.div`
  position: fixed;
  right: 35px;
  top: 125px;
  cursor: pointer;
  color: #fff;
  z-index: 500;

  img {
    width: 40px;
    animation: ${zoomInOut} 3s infinite;
  }
`;




