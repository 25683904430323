import React, { useState, } from 'react'
import { Row, Col, Tooltip } from 'antd'
import Input from 'components/Input'
import SimpleButton from 'components/GlobalButtons/SimpleButton'
import styled from 'styled-components'
import { SyncOutlined } from '@ant-design/icons';
import { useAuthStore, } from 'zustand-stores';
import { AuthStore } from 'zustand-stores/useAuthStore';
import useSyncEmailStore, { SyncEmailStore } from 'zustand-stores/useSyncEmailStore';


type Props = {
  handleSyncEmailSilent: () => void;
  handleSyncEmailLogin: Function;
  onEmailSearch: (value: string) => void;
  onLogEmailClick: () => void;
}

const LogHeader = (props: Props) => {
  const { onLogEmailClick, handleSyncEmailLogin, handleSyncEmailSilent, onEmailSearch } = props;
  const isCrmReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.crm.isReadOnly)
  const isEmailSyncing = useSyncEmailStore((state: SyncEmailStore) => state.isSyncing)

  const [searchText, setSearchText] = useState<string>('')

  const onSearchTextChange = (e: any) => {
    const value = e.target.value
    setSearchText(value)
    if (!value) {
      onSearch()
    }
  }

  const onSearch = () => {
    onEmailSearch(searchText)
  }



  return (
    <HeaderRow >
      <Col style={{ display: 'flex' }}>
        <Input
          placeholder={`Search Emails`}
          style={{ width: '300px' }}
          type='search'
          onChange={onSearchTextChange}
          onSearch={onSearch}
          allowClear
        />
      </Col>
      {
        !isCrmReadOnly &&
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title="Refresh">
            <SyncIcon spin={isEmailSyncing} disabled={isEmailSyncing} onClick={handleSyncEmailSilent} />
          </Tooltip>
          <SimpleButton
            style={{ float: 'right', padding: '4px 20px', marginRight: '1rem' }}
            text={isEmailSyncing ? 'Syncing Emails...' : `Sync Email`}
            onClick={handleSyncEmailLogin}
            outlined
            loading={isEmailSyncing}
          />
          <SimpleButton
            style={{ float: 'right', padding: '4px 20px' }}
            text={`Log Email`}
            onClick={onLogEmailClick}
          />

        </Col>
      }

    </HeaderRow >
  )
}

export default LogHeader

const HeaderRow = styled(Row)`
  // position: -webkit-sticky;
  // position: sticky;
  // top: 45px;
  // z-index: 10;
  background-color: inherit;
  padding: 20px 0;
  margin-top: -20px !important;
  margin-bottom: -20px !important;
  display: flex;
  justify-content: space-between;
`

const SyncIcon = styled(SyncOutlined)`
  margin-right: 1rem;
  font-size: 15px;
  padding: 5px;

  cursor: ${({ disabled }: { disabled?: boolean }) => disabled ? 'not-allowed !important' : 'pointer'};

  &:hover {
    background-color: #33334d;
    border-radius: 30%;
    filter: brightness(1.2);
  }

  

`