import AWS from 'aws-sdk'
import { RecordAPI, UserAPI } from 'containers/Pages/RelationshipManagement/apis'
import { create } from 'zustand'
import useUserStore from './useUserStore'
import { getSessionStorage } from 'utilities/Functions/GlobalHelperFunctions'
import { message } from 'antd'
import useAuthStore from './useAuthStore'

type State = {
  isSyncing: boolean,
  isFetching: boolean,
  bucket: string,
  emailStorePath: string,
  lastEmailPull: string,
  emailsData: { [syncObjectId: string]: any[] },
}

type Action = {
  setIsSyncing: (isSyncing: boolean) => void,
  setEmailsData: (syncObjectId: string, data: any[]) => void,
  syncEmails: (accessToken: string, syncObjectId: string) => Promise<void>,
  getEmailsFromS3: (syncObjectId: string) => Promise<void>,
  getEmailStorageInfo: () => Promise<void>,
}

export type SyncEmailStore = State & Action

const initialState: State = {
  isSyncing: false,
  isFetching: false,
  bucket: '',
  emailStorePath: '',
  lastEmailPull: '',
  emailsData: {},
}

const SYNC_CHECK_INTERVAL = 5000  // 5s
const MAX_SYNC_TIME = 300000  // 5 minutes

/**
 * A custom hook that creates a Zustand store for managing email state globally.
 * @returns An object containing state values and functions for updating the state.
 */
const useSyncEmailStore = create<SyncEmailStore>((set, get) => ({
  ...initialState,
  setIsSyncing: (isSyncing: boolean) => set({ isSyncing }),
  setEmailsData: (syncObjectId: string, data: any[]) => set((state: SyncEmailStore) => {
    const emailsData = { ...state.emailsData }
    emailsData[syncObjectId] = data
    return { emailsData }
  }),
  getEmailStorageInfo: async () => {
    const username = useAuthStore.getState().username
    const crmUser = await fetchCrmUser(username)
    if (crmUser) {
      set({
        bucket: crmUser?.bucket,
        emailStorePath: crmUser?.email_store_s3_path,
        lastEmailPull: crmUser?.last_email_pull
      })
    }
  },
  getEmailsFromS3: async (syncObjectId: string) => {
    set({ isFetching: true })
    const { bucket, emailStorePath } = get()
    console.log('getEmailsFromS3', syncObjectId, emailStorePath, bucket);
    try {
      if (!bucket || !emailStorePath || !syncObjectId) {
        throw new Error('Missing required data to fetch emails')
      }
      const emails = await fetchEmailsFromS3(syncObjectId, emailStorePath, bucket)
      set((state: SyncEmailStore) => {
        const emailsData = { ...state.emailsData }
        emailsData[syncObjectId] = emails
        return { emailsData }
      })
    } catch (e) {
      console.log('getEmailsFromS3', e);
    } finally {
      set({ isFetching: false })
    }
  },
  syncEmails: async (accessToken: string, syncObjectId: string) => {
    set({ isSyncing: true })
    // Sync all emails associated to the access token(email account)
    // The syncing process may take long, it should happen in the background
    // In the meantime, disable the sync button by setting isSyncing to true

    let syncRequestSuccess = false
    let isSyncTimeout = false

    try {
      message.info('Syncing emails... Please don\'t refresh the page')
      await RecordAPI.syncEmails(accessToken)
      syncRequestSuccess = true
    } catch (e) {
      const err: any = e
      console.log('syncEmails', err, err?.response);
      syncRequestSuccess = false
      if (err?.response?.status === 504) {
        isSyncTimeout = true
      } else {
        // Error syncing emails
        message.error('Failed to sync emails')
      }
    }

    if (syncRequestSuccess) {

      try {
        // Fetch emails from S3 once the syncing is done
        await get().getEmailsFromS3(syncObjectId)
        // Update last_email_pull in the user store
        const result = await getUserPrevTimeToSyncEmail()
        if (result?.prevTimeToSyncEmail) {
          set({ lastEmailPull: result.prevTimeToSyncEmail })
        }

        message.success('Emails synced successfully')

      } catch (e) {
        console.log('syncEmails', e);
        message.error('Failed to sync emails')
      } finally {
        set({ isSyncing: false })
      }
      return;
    }

    if (isSyncTimeout) {
      // Acceptable timeout, use interval to check if the syncing has finished
      try {
        // Set interval (every 5s) to check if the syncing has finished by comparing the last_email_pull from user
        // If readyToFetch returns true, call fetchEmailsFromS3
        // If the interval runs for 5 minutes and the syncing is still not done, terminate the interval
        const prevTimeToSync = get().lastEmailPull || ''

        let elapsedTime = 0;
        const interval = setInterval(async () => {
          const { ready, user } = await readyToFetch(prevTimeToSync)

          console.log('interval', ready);
          if (ready) {
            // Update last_email_pull in the user store
            if (user?.last_email_pull) {
              set({ lastEmailPull: user?.last_email_pull })
            }

            // Fetch emails from S3 once the syncing is done
            await get().getEmailsFromS3(syncObjectId)
            set({ isSyncing: false })
            message.success('Emails synced successfully')
            clearInterval(interval)
          } else {
            elapsedTime += SYNC_CHECK_INTERVAL;
            if (elapsedTime >= MAX_SYNC_TIME) {
              // Terminate the interval after 5 minutes if it's still running
              clearInterval(interval);
              set({ isSyncing: false })
              message.error('Emails syncing timed out. Please try again.')
            }
          }
        }, SYNC_CHECK_INTERVAL)

      } catch (e) {
        console.log('syncEmails', e);
        message.error('Failed to sync emails')
        set({ isSyncing: false })
      }
    }



  },
}))

export default useSyncEmailStore


/******************************* Helper Functions *******************************/

/**
 * Check when the email was last synced
 * Regularly check the user's attribute of last_email_pull
 * @param prevTimeToSync The previous time the email was synced
 * @returns A boolean value
 */
const readyToFetch = async (prevTimeToSync: string): Promise<{ ready: boolean, user: any }> => {
  const result = await getUserPrevTimeToSyncEmail()

  if (result) {
    const { prevTimeToSyncEmail: curValueFromUser, user } = result
    if (curValueFromUser === prevTimeToSync) {
      // Changed, update the user store
      return {
        ready: true,
        user
      }
    }
  }

  // If the value has changed, return true
  return {
    ready: false,
    user: result?.user
  }
}


/**
 * Fetches emails from S3 bucket.
 * 
 * @param syncObjectId - The ID of the sync object.
 * @param emailStorePath - The path to the email store.
 * @param bucketName - The name of the S3 bucket.
 * @returns The fetched emails as JSON data, or null if there was an error.
 */
const fetchEmailsFromS3 = async (syncObjectId: string, emailStorePath: string, bucketName: string) => {
  const s3Key = `${emailStorePath}/${syncObjectId}`

  console.log('fetchEmailsFromS3', syncObjectId, emailStorePath, bucketName);

  const { bucket, params } = configAWS(bucketName, s3Key)
  const data = await bucket.getObject(params).promise();
  const jsonStr = data.Body?.toString("utf-8") || "";
  const jsonData = JSON.parse(jsonStr)

  if (Array.isArray(jsonData)) {
    // Sort by time, the latest one comes first
    jsonData.sort((a: any, b: any) => {
      return new Date(b.datetime).getTime() - new Date(a.datetime).getTime()
    })

    return jsonData
  }

  throw new Error("Email data is not an array")
}


/**
 * Get the previous time the email was synced
 * @returns A string of the previous time the email was synced
 */
const getUserPrevTimeToSyncEmail = async () => {
  const username = useUserStore.getState().username
  try {
    const user = await fetchCrmUser(username)
    const prevTimeToSyncEmail = user?.last_email_pull
    return { prevTimeToSyncEmail, user }
  } catch (e) {
    console.log('getUserLastEmailPull', e);
    return null
  }
}

const configAWS = (bucketName: string, s3Key: string) => {
  const accessKeyId = getSessionStorage("AccessKeyId")
  const accessKey = getSessionStorage("AccessKeySec")
  const sessionToken = getSessionStorage("sessionToken")

  AWS.config.update({
    accessKeyId: accessKeyId,
    secretAccessKey: accessKey,
    sessionToken: sessionToken,
  });

  const bucket = new AWS.S3({
    params: { Bucket: bucketName, ResponseCacheControl: "no-cache" },
    region: "ap-southeast-2",
  });

  const params = { Bucket: bucketName, Key: s3Key };

  return { bucket, params }
}



const fetchCrmUser = async (username: string) => {
  let crmUser: any = null
  try {
    crmUser = await UserAPI.getUserById(username)
    console.log('crmUser', crmUser)
  } catch (e) {
    console.log('fetchCrmUser', e)
  }
  return crmUser
}