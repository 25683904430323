import { Configuration } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: "c5f3997c-616f-4b9c-b4d4-e9cfcbdbd2f7",
    authority: "https://login.microsoftonline.com/80d6d590-dad4-4550-aff7-2eb7950ac808", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    // redirectUri: "https://dev.itrazo.app/",
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    allowNativeBroker: false // Disables WAM Broker
  }
};


export const loginConfig = {
  // scopes: ["user.read"],
  scopes: ["User.Read", "Mail.ReadBasic", "Mail.Read", "offline_access"],
  prompt: "select_account",
}


