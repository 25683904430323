import React, { useMemo } from 'react'
import { OrderFulfillmentContainer } from '../../../components/Containers/OrderFulFillment'
import { FlexWrapper, SupplierPriceTable } from 'statics/styles/StyledComponents'
import styled from 'styled-components'
import { FullOrderStatus, PRODUCT_TYPE } from 'containers/Pages/InventoryManagement/statics/constants'
import { Descriptions } from 'antd'
import SimpleButton from 'components/GlobalButtons/SimpleButton'
import { DATE_FORMAT } from 'utilities/CONSTANTS'
import moment from 'moment'
import { capitaliseFirst } from 'utilities/Functions/FormatFunctions'




type Props = {
  orderData: any;
  setStep: Function
}

const PaymentContainer = (props: Props) => {
  const { orderData, setStep } = props

  console.log('PaymentContainer orderData', orderData)
  const orderCancelled = orderData?.order_status === FullOrderStatus?.customerCancelled

  const isEcomProduct = orderData?.order_for === PRODUCT_TYPE.eCommerceProduct.value


  const priceData = useMemo(() => {
    const productPrices = orderData?.product_info?.map((product: any) => {
      const price = Number(product.price) * Number(product.quantity)
      return price?.toFixed(2)
    })

    return {
      productPrices: productPrices || [],
      subtotal: Number(orderData?.mrp_price || 0).toFixed(2),
      gst: Number(orderData?.gst_price || 0).toFixed(2),
      total: Number(orderData?.total_price || 0).toFixed(2),
      paid: Number(orderData?.total_price || 0).toFixed(2),
      due: Number(0).toFixed(2),
      paidDate: moment(orderData?.order_date).format(DATE_FORMAT),
      paymentMethod: capitaliseFirst(orderData?.payment_method) || 'Paypal'
    }
  }, [orderData])


  return (
    <React.Fragment>
      <OrderFulfillmentContainer.DetailsContainer>
        <OrderFulfillmentContainer.Header>
          <FlexWrapper flexBetween>
            Payment
            {/* <SimpleButton
              text={'Send Invoice'}
              onClick={() => null}
            /> */}
          </FlexWrapper>
        </OrderFulfillmentContainer.Header>

        <OrderFulfillmentContainer.Body>
          <OrderFulfillmentContainer.Heading>
            Price Breakdown
          </OrderFulfillmentContainer.Heading>

          <PriceBreakdownTable priceData={priceData} />

          {
            isEcomProduct &&
            <>
              <PaymentTable priceData={priceData} />
              <OrderFulfillmentContainer.Heading style={{ marginTop: '30px' }}>
                Payment Method
              </OrderFulfillmentContainer.Heading>
              <StyledText>
                <p>{priceData?.paymentMethod}</p>
                <p>Paid on {priceData.paidDate}</p>
              </StyledText>
            </>
          }


        </OrderFulfillmentContainer.Body>
        {
          orderCancelled ?
            // <OrderFulfillmentContainer.Footer />
            <OrderFulfillmentContainer.Footer btnText='Next >' btnOnClick={() => setStep(1)} />
            :
            <OrderFulfillmentContainer.Footer btnText='Next >' btnOnClick={() => setStep(1)} />
        }
      </OrderFulfillmentContainer.DetailsContainer>
      <OrderFulfillmentContainer.CommentContainer>
        <OrderFulfillmentContainer.Body>
          <OrderFulfillmentContainer.OrderInfoDisplay order={orderData} />
          {/* <OrderFulfillmentContainer.CommentEditor
            orderData={orderData}
            type='payment'
            disabled={orderCancelled}
          /> */}
        </OrderFulfillmentContainer.Body>
      </OrderFulfillmentContainer.CommentContainer>
    </React.Fragment >
  )
}

export default PaymentContainer


const StyledText = styled.div`
  p {
    font-size: 14px;
  }

`

const PriceBreakdownTable = ({ priceData }: { priceData: any }) => {

  const deliveryFee: string = (Number(priceData.total) - Number(priceData.subtotal) - Number(priceData.gst)).toFixed(2)

  return (
    <SupplierPriceTable>
      <tbody>
        {priceData?.productPrices?.map((price: any, index: number) => (
          <tr key={index}>
            <td>{`Product ${index + 1}`}</td>
            <td><span>$ {price}</span></td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td>Subtotal</td>
          <td><span>$ {priceData.subtotal}</span></td>
        </tr>
        <tr>
          <td>GST <span style={{ color: '#8F90A6' }}> (10%)</span></td>
          <td><span>$ {priceData.gst}</span></td>
        </tr>
        {/* <tr>
          <td>Delivery Charges</td>
          <td><span>$ {deliveryFee}</span></td>
        </tr> */}
        <tr>
          <td>Total</td>
          <td><span>$ {priceData.total}</span></td>
        </tr>
      </tfoot>
    </SupplierPriceTable>
  )
}

const PaymentTable = ({ priceData }: { priceData: any }) => {

  return (
    <SupplierPriceTable style={{ marginTop: '20px' }}>
      <tbody>
        <tr>
          <td>Amount Paid</td>
          <td><span>$ {priceData.paid}</span></td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>Amount Due</td>
          <td><span>$ {priceData.due}</span></td>
        </tr>
      </tfoot>
    </SupplierPriceTable>
  )
}

