import React, { useCallback, useEffect, useState } from 'react'
import FilterHeader from '../components/Header/FilterHeader';
import AdiTable from 'components/AdiTable';
import { capitaliseFirst, formatTitle, initialCellValue } from 'utilities/Functions/FormatFunctions';
import { DATE_TIME_FORMAT, ISSUESTATUSOPTIONS, ISSUE_FLOW_STEPS, TABLE_CELL_CONFIG } from 'utilities/CONSTANTS';
import moment from 'moment';
import styled from 'styled-components';
import LogIssueModal from './LogIssueModal';
import FlowInfoPanel from 'components/FlowInfoPanel';
import { PlusOutlined } from '@ant-design/icons';
import IssueAPI from '../apis/IssueAPI';
import { Dropdown, Menu } from 'antd';
import { MoreIcon } from 'statics/styles/StyledComponents';
import { useAuthStore } from 'zustand-stores';
import { AuthStore } from 'zustand-stores/useAuthStore';

type Props = {
  url?: string;
};

const Issues = (props: Props) => {

  const isVctReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.vct.isReadOnly);

  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<any>({
    open: false,
    data: null,
    isEdit: 'add', // value will be edit view resolve
  });
  const [tableData, setTableData] = useState<Object[]>([]);


  const getTableData = async () => {
    setIsDataLoading(true)
    try {
      const res = await IssueAPI.getAll()
      console.log(' res', res)
      if (Array.isArray(res) && res.length > 0) {
        res.sort((a: any, b: any) => {
          if (a instanceof Object &&
            !(a.hasOwnProperty('issue_datetime') && b.hasOwnProperty('issue_datetime'))) {
            return 0;
          } else {
            const aTime: Date = new Date(a.issue_datetime);
            const bTime: Date = new Date(b.issue_datetime);
            return bTime.getTime() - aTime.getTime();
          }
        });
        setTableData(res)
      }


    } catch (error) {
      console.log(' getTableData issues error', error)
    } finally {
      setIsDataLoading(false)
    }
  }


  useEffect(() => {
    getTableData()
  }, []);


  const filterInputConfig = [
    {
      type: 'search',
      placeholder: 'Search by Issue ID, Event ID or EPC ID',
      onChange: () => null,
      onSearch: () => null,
      allowClear: true,
      inputStyle: { width: '300px' }
    },
    {
      type: 'daterange',
      placeholder: 'Select Date Range',
      onChange: () => null
    },
  ];

  const ButtonConfig = [
    {
      text: <BtnText><PlusOutlined />&nbsp;&nbsp;Log Event Issue</BtnText>,
      onClick: () => {
        handleModalOpen()
      }
    }
  ];


  const handleRowClick = (record: any) => {
    setIsModalOpen({
      ...isModalOpen,
      open: true,
      data: record,
      isEdit: 'view',
    });
  }

  const handleModalOpen = () => {
    setIsModalOpen({
      ...isModalOpen,
      open: true,
    })
  }

  const handleModalClose = () => {
    setIsModalOpen({
      ...isModalOpen,
      open: false,
      data: null,
      isEdit: 'edit'
    })
  };

  const renderMenu = (record: any) => {

    const handleMenuClick = async (e: any) => {
      e.domEvent.stopPropagation()
      console.log('rec', record, e)
      const clickedKey = e.key
      setIsModalOpen({
        ...isModalOpen,
        open: true,
        data: record,
        isEdit: clickedKey,
      })



    }

    return (
      <Menu className='dropdownMenu' onClick={handleMenuClick}>
        <Menu.Item key="resolve">
          <div>Mark as Resolved</div>
        </Menu.Item>
        <Menu.Item key="edit">
          <div>Edit</div>
        </Menu.Item>
      </Menu>
    )
  };

  const actionColumns = isVctReadOnly ? [] : [
    {
      title: formatTitle('Action'),
      dataIndex: 'action',
      key: 'action',
      ...TABLE_CELL_CONFIG,
      width: 200,
      align: 'center',
      render: (text: any, record: any) => (
        <>
          {record.status == 'open' ? (
            <Dropdown overlay={renderMenu(record)}>
              <MoreIcon onClick={(e: any) => e.stopPropagation()} />
            </Dropdown>
          ) : 'No Action Required'}

        </>
      ),
    }
  ];

  const finalColumns = [
    ...columns,
    ...actionColumns,
  ];


  return (
    <>
      <FlowInfoPanel
        items={[...ISSUE_FLOW_STEPS]}
        style={{ minWidth: '800px', marginLeft: '32px' }}
      />
      <FilterHeader
        // inputsConfig={filterInputConfig}
        buttonsConfig={ButtonConfig}
      />
      <AdiTable
        marginTop='20px'
        loading={isDataLoading}
        fullWidth
        rowKey={(record: any) => record.issue_id}
        onRowClick={(record: any) => handleRowClick(record)}
        tableData={tableData}
        columns={finalColumns}
      />
      <LogIssueModal
        isModalOpen={isModalOpen.open}
        selectedIssue={isModalOpen.data}
        handleModalClose={handleModalClose}
        isEdit={isModalOpen.isEdit}
        initializeAll={() => {
          getTableData()
        }}
      />
    </>
  )
}

export default Issues

const BtnText = styled.span`
  color: var(--white-text-color);
  display: flex;
  align-items: center;
`;

const columns: any = [
  {
    title: formatTitle('Issue ID'),
    dataIndex: 'issueId',
    key: 'issueId',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle('Date & Time'),
    dataIndex: 'issue_datetime',
    key: 'issue_datetime',
    ...TABLE_CELL_CONFIG,
    width: 180,
    render: (text: any, record: any) => text ? moment(text).format(DATE_TIME_FORMAT) : initialCellValue(text)
  },
  {
    title: formatTitle('Priority'),
    dataIndex: 'priority',
    key: 'priority',
    ...TABLE_CELL_CONFIG,
    width: 164,
    render: initialCellValue,
  },
  {
    title: formatTitle('Event ID'),
    dataIndex: 'event_id',
    key: 'event_id',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle('EPC ID'),
    dataIndex: 'epc_id',
    key: 'epc_id',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle('Business Step'),
    dataIndex: 'business_step',
    key: 'business_step',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle('Business Location'),
    dataIndex: 'business_location',
    key: 'business_location',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle('Custody Owner'),
    dataIndex: 'custody_owner',
    key: 'custody_owner',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle('Status'),
    dataIndex: 'status',
    key: 'status',
    ...TABLE_CELL_CONFIG,
    filters: ISSUESTATUSOPTIONS.map((item: any) => {
      return {
        text: item.label,
        value: item.value,
      }
    }),
    onFilter: (value: string, record: any) => record.status === value,
    render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
  },
]

const staticTableData = [
  {
    issue_id: 'ISU1001',
    date_time: '2023-09-18T10:20:32.0Z',
    priority: 'High',
    product_id: 'PROD001',
    event_id: 'EVE002',
    epc_id: '140069',
    epc_type: 'SGTIN',
    business_step: 'Shipping',
    business_location: '627 Rowella Road, Rowella, Tasmania, 7277',
    current_custody: 'Logistic Owner',
    custody_owner: 'Petuna',
    comment: 'Bacteria Contamination',
    status: 'resolved',
    detailed_description: 'The temperature monitoring records for this shipment indicate that the temperature exceeded the specified limit for a significant portion of the transit period. This temperature deviation led to the proliferation of harmful bacteria in the fish.',
  },
  // {
  //   issue_id: 'ISU1003',
  //   date_time: '2023-09-28T10:15:00.000Z',
  //   priority: 'Medium',
  //   product_id: 'PROD002',
  //   event_id: 'EVT1003',
  //   epc_id: 'EPC 02',
  //   epc_type: 'SGTIN',
  //   business_step: 'Receiving',
  //   business_location: 'Warehouse A',
  //   current_custody: 'Warehouse A',
  //   custody_owner: 'Petuna',
  //   comment: 'Product Contamination',
  //   detailed_description: 'Product Contamination',
  // },
]



