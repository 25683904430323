import React, { useState, useEffect } from 'react'
import GlobalModal from 'components/GlobalModal'
import { FlexWrapper, FormRequireText, IntroText, TrackGridThree } from 'statics/styles/StyledComponents';
import { Form, Row, Col, message, Steps } from 'antd';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import Input from 'components/Input';
import { VALIDATION_RULES } from 'utilities/inputValidation';
import { IContactPayload } from '../../statics/types';
import { BusinessAPI, ContactAPI, LeadAPI, UserAPI } from '../../apis'
import { BusinessType } from '../../statics/types';
import { getSelectorOptions } from 'utilities/Functions/GlobalHelperFunctions';
import { titleOptions } from '../../statics/constants'
import { useSimilarityCheck } from '../../hooks';


import AddressSearchInputFormItem from 'components/AddressSearchInput/AddressSearchInputFormItem';
import { useResponsive } from 'utilities/hooks';
import { useGeneralStore, useUserStore } from 'zustand-stores';
import { UserStore } from 'zustand-stores/useUserStore';
import { GeneralStore } from 'zustand-stores/useGeneralStore';


type forrmTypes = {
  title: string;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  job_title: string;
  business_type: string;
  business_ID: string;
  assigned_to: string;
  created_by: string;
  active: boolean;
  status: string;
  address: any[];
  full_name: string;
  unit: string;
  street: string;
  suburb: string;
  postcode: string;
  state: string;
  country: string;
  type: string;
  contact_ID: string;
  is_manual_input: string;
  full_address: string;
}

type Props = {
  open: boolean;
  onCancel: () => void;
  isEdit: boolean;
  selectedContact: any
  setReload: () => void;
}

const CreateContact = (props: Props) => {
  const { open, onCancel, isEdit, selectedContact, setReload } = props;
  const { Step } = Steps;
  const username = useUserStore((state: UserStore) => state.username)

  const [step, setStep] = useState(0);
  const [addressState, setAddressState] = useState<any>({
    unit: "",
    street: "",
    suburb: "",
    postcode: "",
    state: "",
    country: "",
    full_address: "",
  });



  const [form] = Form.useForm();

  const emptyContactData: forrmTypes = {
    title: "",
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    job_title: "",
    business_ID: "",
    business_type: "",
    assigned_to: "",
    created_by: "",
    active: true,
    status: "",
    address: [],
    full_name: "",
    unit: "",
    street: "",
    suburb: "",
    postcode: "",
    state: "",
    country: "", // remove the default value
    type: "",
    contact_ID: "",
    is_manual_input: 'false',
    full_address: "",
  }

  const [contactData, setContactData] = useState<forrmTypes>(emptyContactData);
  const [contactLocationAddress, setContactLocationAddress] = useState<any>();
  // const [businessType, setBusinessType] = useState<any>('');

  const screen = useResponsive()
  // custom hook that checks for duplicate company
  const similarityCheck = useSimilarityCheck()

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)

  useEffect(() => {
    if (isEdit && selectedContact) {
      console.log('selectedContact', selectedContact)
      // load data

      // split full name into first and last name
      selectedContact.first_name = selectedContact.full_name?.split(' ').slice(0, -1).join(' ')
      selectedContact.last_name = selectedContact.full_name?.split(' ').slice(-1).join(' ')

      // remove empty space from phone value
      selectedContact.phone = selectedContact.phone?.replaceAll(' ', '')

      selectedContact.status = selectedContact.active == true ? 'Active' : 'Inactive'
      selectedContact.contact_ID = selectedContact.contact_ID
      selectedContact.is_manual_input = selectedContact.contact_address && selectedContact.contact_address?.is_manual_input?.toString() || 'false'
      selectedContact.unit = selectedContact.contact_address && selectedContact.contact_address.unit
      selectedContact.street = selectedContact.contact_address && selectedContact.contact_address.street
      selectedContact.suburb = selectedContact.contact_address && selectedContact.contact_address.suburb
      selectedContact.postcode = selectedContact.contact_address && selectedContact.contact_address.postcode
      selectedContact.state = selectedContact.contact_address && selectedContact.contact_address.state
      selectedContact.country = selectedContact.contact_address && selectedContact.contact_address.country
      if (selectedContact.contact_address) {
        setContactLocationAddress(selectedContact.contact_address);
      }
      form.setFieldsValue(selectedContact)
    }
  }, [isEdit])



  // useEffect(() => {
  //   ContactAPI.getCompanyNamesByBusId()
  //     .then(res => {
  //       // setCompanyNameList(res)
  //     }).catch(e => {
  //       message.error({ content: `Failed to get all company data.` })
  //     })

  // }, [])

  const readyToConfirms = (finalPayload: any) => {
    // ADI_CONFIRM({
    //   actionTitle: isEdit ? 'update the contact' : 'add a contact',
    //   onConfirm: () => isEdit ? updateContact(finalPayload) : createContact(finalPayload)
    // })
    if (isEdit) {
      updateContact(finalPayload)
    } else {
      createContact(finalPayload)
    }

  };


  const createContact = (payload: IContactPayload) => {
    const key = 'creating'
    // message.loading({ content: 'Creating contact...', key })
    setIsGlobalLoading(true)

    ContactAPI.createContact(payload)
      .then(() => {
        message.success({
          content: 'Contact created successfully!',
          duration: 1,
          key,
          onClose: () => {
            onCloseModal()
            setReload()
          }
        })
      }).catch(() => {
        message.error(`Failed to create contact`)
      }).finally(() => {
        setIsGlobalLoading(false)
      })
  }

  const updateContact = (payload: IContactPayload) => {
    const key = 'updatinging'
    // message.loading({ content: 'Updating contact...', key })
    setIsGlobalLoading(true)

    ContactAPI.updateContact(payload)
      .then(() => {
        message.success({
          content: 'Contact updated successfully!',
          duration: 1,
          key,
          onClose: () => {
            onCloseModal()
            setReload()
          }
        })
      }).catch(() => {
        message.error(`Failed to update contact`)
      }).finally(() => {
        setIsGlobalLoading(false)
      })
  }

  const renderFooterBtns = () => {
    return (
      <FlexWrapper flexEnd key='0'>
        <Form.Item style={{ marginBottom: '0' }}>
          {step === 0 && similarityCheck.errorMessage.similarExist &&
            <SimpleButton
              text='Ignore'
              id='modal-btn-width-regular'
              onClick={(e: any) => {
                e.preventDefault();
                similarityCheck.handleIgnore()
              }}
              isCancel
            />
          }
          {
            step !== 0 &&
            <SimpleButton
              text='Back'
              id='modal-btn-width-regular'
              onClick={goBack}
              isCancel
            />
          }
          <SimpleButton
            text={step === 2 ? isEdit ? 'Update' : 'Create' : 'Next'}
            id='modal-btn-width-regular'
            htmlType='submit'
            form={step === 0 ? 'contact-info' : step === 1 ? 'company-info' : 'address-info'}
          />
        </Form.Item>

      </FlexWrapper>
    )
  };

  const onCloseModal = () => {
    onCancel()
    setStep(0)
    setContactData(emptyContactData)
    form.setFieldsValue({ ...emptyContactData, })
    setContactLocationAddress({
      unit: "",
      street: "",
      suburb: "",
      postcode: "",
      state: "",
      country: "",
      full_address: "",
    }) // force to clear this state
  }

  const readyToConfirm = async (values: any) => {
    console.log('success', values, form.getFieldsValue())
    values.first_name = values.first_name?.trim()
    values.last_name = values.last_name?.trim()
    const { business_type, email, phone, first_name, last_name, assigned_to, business_ID, job_title, status } = values

    if (step == 0) {
      // check similarity
      values = { ...values, confirm_similarity: similarityCheck.errorMessage.ignored ? true : false }
      await similarityCheck.checkContactSimilarity(first_name, last_name, email, phone, selectedContact?.contact_ID, () => {
        // check successfully
        setStep(step + 1)
        setContactData({ ...values })
      })
    } else if (step == 1) {
      if (business_ID) {
        const businessId: any = business_ID;
        await BusinessAPI.getBusinessById(business_type, businessId).then(allBusiness => {
          if (allBusiness) {
            const deliveryAddress = allBusiness.address_details.delivery[0]
            if (deliveryAddress) {
              const fullAddress = `${deliveryAddress.unit ? deliveryAddress.unit + '/' : ''} ${deliveryAddress.street ? deliveryAddress.street + ',' : ''} ${deliveryAddress.suburb ? deliveryAddress.suburb + ',' : ''}  ${deliveryAddress.country ? deliveryAddress.country + ',' : ''} ${deliveryAddress.state ? deliveryAddress.state + ',' : ''} ${deliveryAddress.postcode ? deliveryAddress.postcode : ''}`
              console.log('fullAddress==>', fullAddress)
              console.log('deliveryAddress====>', deliveryAddress)
              setContactData({
                ...contactData,
                job_title: job_title,
                business_ID: business_ID,
                business_type: business_type,
                assigned_to: assigned_to,
                status: status,
                unit: deliveryAddress.unit,
                country: deliveryAddress.country,
                postcode: deliveryAddress.postcode,
                state: deliveryAddress.state,
                street: deliveryAddress.street,
                suburb: deliveryAddress.suburb,
                type: 'Delivery',
                full_address: fullAddress,
              })
              if (step < 2) setStep(step + 1)
            } else {
              console.log('notdeliveryAddress====>', deliveryAddress)
              setContactData({
                ...contactData,
                job_title: job_title,
                business_ID: business_ID,
                business_type: business_type,
                assigned_to: assigned_to,
                status: status,
                type: 'Delivery',
                unit: '',
                country: '',
                postcode: '',
                state: '',
                street: '',
                suburb: '',
                full_address: '',
              })
              if (step < 2) setStep(step + 1)
            }
          } else {
            if (step < 2) setStep(step + 1)
          }
        }).catch(err => {
          console.log('err', err)
        })
      } else if (business_ID == undefined || business_ID == '') {
        console.log('contactlocationinfo edit state===>', business_ID, contactLocationAddress, contactData)
        if (contactLocationAddress) {
          setContactData({
            ...contactData,
            job_title: job_title,
            business_ID: business_ID,
            assigned_to: assigned_to,
            status: status,
            country: contactLocationAddress.country,
            postcode: contactLocationAddress.postcode,
            state: contactLocationAddress.state,
            street: contactLocationAddress.street,
            suburb: contactLocationAddress.suburb,
            unit: contactLocationAddress.unit,
            type: 'Location',
            full_address: '',
          })
        } else {
          setContactData({
            ...contactData,
            job_title: job_title,
            business_ID: business_ID,
            assigned_to: assigned_to,
            status: status,
            // country: 'Australia',
            country: '',
            postcode: '',
            state: '',
            street: '',
            suburb: '',
            unit: '',
            type: 'Location',
            full_address: '',
          })
        }
        setStep(step + 1)
      } else {
        setContactData({
          ...contactData,
          job_title: job_title,
          business_ID: business_ID,
          assigned_to: assigned_to,
          status: status,
          type: 'Location',
        })
        if (step < 2) setStep(step + 1)
      }
    } else if (step == 2) {
      // not in use
      let addressDetails: any = {};

      addressDetails = {
        ...values,
        is_manual_input: contactData.business_ID ? 'false' : values?.is_manual_input?.toString() || 'false',
      }

      const fullName = `${contactData.first_name} ${contactData.last_name}`;
      // remove the full_address attribute from addressState before add to payload
      delete addressState.full_address

      const payload = {
        contact_ID: isEdit ? selectedContact.contact_ID : '',
        assigned_to: contactData.assigned_to ? contactData.assigned_to : '',
        business_ID: contactData.business_ID ? contactData.business_ID : '',
        business_type: contactData.business_ID ? contactData.business_type : '',
        created_by: username ? username : '',
        full_name: fullName ? fullName : '',
        email: contactData.email ? contactData.email : '',
        phone: contactData.phone ? contactData.phone : '',
        title: contactData.title ? contactData.title : '',
        job_title: contactData.job_title ? contactData.job_title : '',
        active: contactData.status == 'Inactive' ? false : true,
        contact_address: contactData.business_ID ? isEdit ? contactLocationAddress : addressDetails : addressDetails
        // contact_address: contactData.business_ID ? isEdit ? addressState : '' : addressState
      }
      console.log('readyToConfirmspayload', payload, contactData.business_ID, addressState, addressDetails)
      // return
      readyToConfirms(payload);
    }
  }

  useEffect(() => {
    if (step == 2) {
      console.log('step2contactData', contactData, addressState)
      /// check if addressState has full_address is not empty then 
      // set setAddressState value from that otherwise set from contactData
      // if (addressState.full_address) {
      //   setAddressState({
      //     unit: addressState.unit ? addressState.unit : '',
      //     country: addressState.country ? addressState.country : '',
      //     postcode: addressState.postcode ? addressState.postcode : '',
      //     state: addressState.state ? addressState.state : '',
      //     street: addressState.street ? addressState.street : '',
      //     suburb: addressState.suburb ? addressState.suburb : '',
      //     full_address: addressState.full_address ? addressState.full_address : ''
      //   })
      // } else {
      //   setAddressState({
      //     unit: contactData.unit ? contactData.unit : '',
      //     country: contactData.country ? contactData.country : '',
      //     postcode: contactData.postcode ? contactData.postcode : '',
      //     state: contactData.state ? contactData.state : '',
      //     street: contactData.street ? contactData.street : '',
      //     suburb: contactData.suburb ? contactData.suburb : '',
      //     full_address: `${contactData.unit ? contactData.unit + ', ' : ''}${contactData.street ? contactData.street + ', ' : ''}${contactData.suburb ? contactData.suburb + ', ' : ''}${contactData.state ? contactData.state + ', ' : ''}${contactData.country ? contactData.country + ', ' : ''}${contactData.postcode ? contactData.postcode : ''}`
      //   })
      //   form.setFieldsValue({ ...contactData })
      // }

      const fullAddress = `${contactData.unit ? contactData.unit + '/' : ''} ${contactData.street ? contactData.street + ',' : ''} ${contactData.suburb ? contactData.suburb + ',' : ''}  ${contactData.country ? contactData.country + ',' : ''} ${contactData.state ? contactData.state + ',' : ''} ${contactData.postcode ? contactData.postcode : ''}`
      console.log('fullAddress==>', fullAddress, contactData.business_ID)

      form.setFieldsValue({
        ...contactData,
        full_address: fullAddress,
      });

    }
  }, [step])

  const goBack = () => {
    if (step > 0) setStep(step - 1)
  }

  return (
    <GlobalModal
      title={`${isEdit ? 'Edit' : 'Create'} Contact`}
      open={open}
      onCancel={onCloseModal}
      footer={[renderFooterBtns()]}
    >
      {/* <div id='crm-steps' style={{ marginBottom: '35px' }}> */}
      <div style={{ width: screen.xs ? '100%' : 'fit-content', margin: 'auto' }}>
        <Steps current={step} labelPlacement="vertical" size="small">
          <Step title="Contact" />
          <Step title="Company" />
          <Step title="Address" />
        </Steps>
      </div>
      <FormRequireText>
        <span>*</span>
        &nbsp;Fields marked with (*) are required.
      </FormRequireText>
      {
        step === 0 ?
          <ContactInfo
            form={form}
            onFinish={readyToConfirm}
            similarityCheck={similarityCheck}
          />
          : step === 1 ?
            <CompanyInfo
              form={form}
              onFinish={readyToConfirm}
              // businessType={businessType}
              // setBusinessType={setBusinessType}
              isEdit={isEdit}
              selectedBusinessID={selectedContact?.business_ID}
            />
            : step === 2 &&
            <AddressInfo
              form={form}
              onFinish={readyToConfirm}
              payload={contactData}
              addressState={addressState}
              setAddressState={setAddressState}
            />
      }
    </GlobalModal>
  )

}

export default CreateContact

type FormProps = {
  form: any;
  onFinish: (values: any) => void;
  payload?: any;
  similarityCheck?: any;
  setBusinessType?: Function;
  businessType?: string | any;
  isEdit?: boolean;
  selectedBusinessID?: string;
  addressState?: any;
  setAddressState?: any;
}

const ContactInfo = (props: FormProps) => {
  const { form, onFinish, similarityCheck } = props;

  const renderOptions = (type?: string) => {
    if (type == 'title') {
      return getSelectorOptions(titleOptions, 'Select Title', 'value', 'label')
    } else {
      return getSelectorOptions([], 'Select')
    }
  };


  return (
    <Form
      form={form}
      name='contact-info'
      onFinish={onFinish}
    >
      <TrackGridThree>
        <Form.Item
          name="title"
          initialValue={''}
        >
          <Input
            label="Title"
            name="title"
            placeholder="Select Title"
            options={renderOptions('title')}
            type="select"
            allowClear
          />
        </Form.Item>
        <Form.Item
          name="first_name"
          initialValue={''}
          rules={[
            { required: true, message: 'Please enter the First Name.' },
            VALIDATION_RULES.MAX.FIRST_NAME,
            VALIDATION_RULES.SPECIAL_CHAR
          ]}
        >
          <Input
            label="First Name"
            name="first_name"
            type="text"
            placeholder="First name"
            required={true}
            onChange={() => similarityCheck.resetErrorMessage()}
          />
        </Form.Item>
        <Form.Item
          name="last_name"
          initialValue={''}
          rules={[
            { required: true, message: 'Please enter the Last Name.' },
            VALIDATION_RULES.MAX.LAST_NAME,
            VALIDATION_RULES.SPECIAL_CHAR
          ]}
        >
          <Input
            label="Last Name"
            name="last_name"
            type="text"
            placeholder="Last Name"
            required={true}
            onChange={() => similarityCheck.resetErrorMessage()}
          />
        </Form.Item>
      </TrackGridThree>
      <Form.Item
        name="email"
        initialValue={''}
        rules={[VALIDATION_RULES.TYPE.EMAIL,
        { required: true, message: 'Please enter an Email Address.' },
        ]}
        validateTrigger={['onBlur']}
      >
        <Input
          label="Email"
          name="email"
          type="text"
          placeholder="Email"
          required={true}
          onChange={() => similarityCheck.resetErrorMessage()}
        />
      </Form.Item>
      <Form.Item
        name='phone'
        initialValue={''}
        rules={[VALIDATION_RULES.MAX.PHONE, { required: true, message: 'Please enter the Phone Number.' },]}
      >
        <Input
          label="Phone Number"
          type="phone"
          required={true}
          onChange={() => similarityCheck.resetErrorMessage()}
        />
      </Form.Item>
      {similarityCheck.renderErrorMessage()}
    </Form>
  )
}

const CompanyInfo = (props: FormProps) => {
  const { form, onFinish } = props;
  // const [companyOptions, setCompanyOptions] = useState<any>([]);
  const [contactOwnerOptions, setContactOwnerOptions] = useState<any>([]);

  const [allCompaniesData, setAllCompaniesData] = useState<any>([]);

  const [allCompanyOptionData, setAllCompanyOptionData] = useState<any>({
    leads: [],
    customers: [],
    suppliers: [],
  });
  const [companyOptions, setCompanyOptions] = useState<any>([]);

  const [random, setRandom] = useState<any>(0);

  const triggerRerender = () => {
    setRandom(Math.random());
  }


  console.log('companyOptions', companyOptions)
  console.log('laaaaaaaaaaaaaaaaaa formvalues', form.getFieldsValue())

  useEffect(() => {
    getCompanyOptions().then(optionData => {
      const busType = form.getFieldValue('business_type')
      // update company options
      if (busType == BusinessType.Customer) {
        setCompanyOptions(optionData.customers)
      } else if (busType == BusinessType.Supplier) {
        setCompanyOptions(optionData.suppliers)
      } else if (busType == BusinessType.Lead) {
        setCompanyOptions(optionData.leads)
      }
    })
    getContactOwnerOptions()

  }, [])

  const getCompanyOptions = async () => {
    // get companies
    let leads = []
    let customers = []
    let suppliers = []
    let leadsWithBusinessType = []
    let customersWithBusinessType = []
    let suppliersWithBusinessType = []
    try {
      /////// Here can use CompanyAPI.getAllCompanies() to get all types of companies
      leads = await LeadAPI.getAllLeads()
      customers = await BusinessAPI.getBusList(BusinessType.Customer)
      suppliers = await BusinessAPI.getBusList(BusinessType.Supplier)
    } catch (e) {
      const err: any = e
      message.error(`Failed to load companies: ${err.message}`)
    }


    // format data

    // as removed the unassigned company option

    // if (contacted) {
    //   let compOptions = contacted.map((item: any) => ({ label: item.bus_name, value: item.company_ID }))
    //   let compSelection = {
    //     label: 'Unassigned Companies',
    //     options: compOptions
    //   }
    //   options.push(compSelection)
    // }

    const optionData: any = {
      leads: [],
      customers: [],
      suppliers: []
    }

    if (leads) {
      const leadOptions = leads.map((item: any) => ({ label: item.bus_name, value: item.lead_ID }))
      leadsWithBusinessType = leads.map((item: any) => ({ ...item, business_type: BusinessType.Lead }))

      optionData.leads = leadOptions
    }

    if (customers) {
      const custOptions = customers.map((item: any) => ({ label: item.bus_name, value: item.customer_ID }))
      customersWithBusinessType = customers.map((item: any) => ({ ...item, business_type: BusinessType.Customer }))

      optionData.customers = custOptions
    }

    if (suppliers) {
      const suppOptions = suppliers.map((item: any) => ({ label: item.bus_name, value: item.supplier_ID }))
      suppliersWithBusinessType = suppliers.map((item: any) => ({ ...item, business_type: BusinessType.Supplier }))

      optionData.suppliers = suppOptions
    }

    setAllCompanyOptionData(optionData)


    const allCompaniesGlobalData = [...leadsWithBusinessType, ...customersWithBusinessType, ...suppliersWithBusinessType]
    setAllCompaniesData(allCompaniesGlobalData)

    return optionData
    // // for edit state need to set the business type
    // if (isEdit) {
    //   const selectedCompany = Array.isArray(allCompaniesGlobalData) && allCompaniesGlobalData.find((item: any) => item?.supplier_ID == selectedBusinessID || item?.customer_ID == selectedBusinessID || item?.lead_ID == selectedBusinessID)
    //   if (selectedCompany && setBusinessType) {
    //     setBusinessType(selectedCompany.business_type)
    //   }
    // } else {
    //   setCompanyOptions(optionData.customers)  // by default 
    // }
  }

  const getContactOwnerOptions = async () => {
    const options: any = []
    let users: any = []
    try {
      users = await UserAPI.getAllUsers()
    } catch (e) {
      const err: any = e
      message.error(`Failed to load companies: ${err.message}`)
    }

    if (users) {
      const contactOptions = users.map((item: any) =>
        ({ label: item.full_name, value: item.username }))
      const userSelection = {
        label: 'All Users',
        options: contactOptions
      }
      options.push(userSelection)
    }
    setContactOwnerOptions(options)
  }

  const renderTypeOption = (type?: string) => {
    if (type == 'status') {
      const statusList = ['Active', 'Inactive']
      return getSelectorOptions(statusList, '')
    } else {
      return getSelectorOptions([], 'Select')
    }
  }

  const getCompanyOptionsByBusType = (busType: string) => {
    // update company options
    if (busType == BusinessType.Customer) {
      setCompanyOptions(allCompanyOptionData.customers)
    } else if (busType == BusinessType.Supplier) {
      setCompanyOptions(allCompanyOptionData.suppliers)
    } else if (busType == BusinessType.Lead) {
      console.log('laaaaaaaaaaaaaaaaaa LEAD', allCompanyOptionData.leads)
      setCompanyOptions(allCompanyOptionData.leads)
    }
  }



  // const onBusinessIDChange = (value: any) => {
  //   console.log('onBusinessIDChange', allCompaniesData, value)
  //   let findBusinessType: string = ''
  //   //to find the matching Id with the selected value, need to check item has suuplier_ID or customer_ID or lead_ID
  //   let selectedCompany = Array.isArray(allCompaniesData) && allCompaniesData.find((item: any) => item?.supplier_ID == value || item?.customer_ID == value || item?.lead_ID == value)
  //   console.log('selectedCompany', selectedCompany)
  //   findBusinessType = selectedCompany?.business_type || ''
  //   if (setBusinessType) setBusinessType(findBusinessType)
  // }

  console.log('business_type', form.getFieldValue('business_type'))

  return (
    <Form
      form={form}
      name='company-info'
      onFinish={onFinish}
      initialValues={{ status: 'Active' }}
    >
      <Form.Item
        name='business_type'
        getValueFromEvent={(e: any) => {
          // form.setFieldsValue({ business_type: e })
          // trigger component re-render
          triggerRerender()
          return e
        }}
      >
        <Input
          label="Company Type"
          type="select"
          options={[
            { label: 'Customer', value: BusinessType.Customer },
            { label: 'Supplier', value: BusinessType.Supplier },
            { label: 'Lead', value: BusinessType.Lead },
          ]}
          // value={businessType}
          placeholder='Select a Company Type (optional)'
          onChange={(value: any) => {
            // if (setBusinessType) setBusinessType(value)
            getCompanyOptionsByBusType(value)
            form.setFieldsValue({ business_ID: '' })
          }}
          allowClear
        />
      </Form.Item>
      <Form.Item
        name='business_ID'
        rules={[{ required: form.getFieldValue('business_type') ? true : false, message: 'Please select a Company.' }]}
      >
        <Input
          label="Company"
          type="select"
          name='business_ID'
          options={companyOptions}
          showSearch
          placeholder='Select a Company (optional)'
          allowClear
          required={form.getFieldValue('business_type') ? true : false}
          disabled={form.getFieldValue('business_type') ? false : true}
        /></Form.Item>
      <Form.Item
        name='job_title'
        rules={[VALIDATION_RULES.MAX.BUSINESS_NAME,
        { required: true, message: 'Please enter the Job Title.' },
        ]}
      >
        <Input label="Job Title" type="text" name='job_title'
          placeholder="Title"
          required={true} />
      </Form.Item>

      <Form.Item
        name='status'
      >
        <Input
          label="Status"
          type="select"
          name='status'
          options={renderTypeOption('status')}
          showSearch
          placeholder='Select a Status (optional)'
        /></Form.Item>
      <Form.Item
        name='assigned_to'
      >
        <Input
          label="Contact Owner"
          type="select"
          name='assigned_to'
          options={contactOwnerOptions}
          showSearch
          placeholder='Select a Owner (optional)'
          allowClear
        /></Form.Item>
    </Form>
  )
}

const AddressInfo = (props: FormProps) => {
  const { form, onFinish, payload, addressState } = props;
  console.log('AddressInfo', payload, addressState)

  const renderAddressTypeOption = (type?: string) => {
    if (type == 'address') {
      const addressTypeList = ['Delivery Address', 'Billing Address', 'Location']
      return getSelectorOptions(addressTypeList, 'Select Address Type (optional)')
    } else {
      return getSelectorOptions([], 'Select')
    }
  }

  return (payload.type == 'Delivery' && payload.country == '') ? (
    // no need this one part now
    <Form
      form={form}
      name='address-info'
      onFinish={onFinish}
    >
      <IntroText><span style={{ color: 'var(--primary-color)' }}>There is no address attached to this company</span></IntroText>

      <Form.Item
        name='type'
      >
        <Input
          label="Address Type"
          type="select"
          options={renderAddressTypeOption('address')}
          showSearch
          placeholder='Select a address type (optional)'
          disabled={true}
          allowClear
        />
      </Form.Item>
      <Row gutter={[30, 15]}>
        <Col sm={{ span: 24 }} xl={{ span: 24 }}>
          <Input label="Address" type="text" name='full_address'
            disabled={form.getFieldValue('business_ID') != '' && form.getFieldValue('business_ID') != undefined ? true : false}
          />
        </Col>
      </Row>
      {/* <Row gutter={[30, 15]}>
        <Col sm={{ span: 24 }} xl={{ span: 12 }}>
          <Form.Item
            name='unit'
            rules={[VALIDATION_RULES.MAX.STREET]}
          >
            <Input label="Unit/Suite" type="text"
              disabled={form.getFieldValue('business_ID') != '' && form.getFieldValue('business_ID') != undefined ? true : false}
            />
          </Form.Item>
        </Col>
        <Col sm={{ span: 24 }} xl={{ span: 12 }}>
          <Form.Item
            name='street'
            rules={[VALIDATION_RULES.MAX.STREET]}
          >
            <Input label="Street Address" type="text"
              disabled={form.getFieldValue('business_ID') != '' && form.getFieldValue('business_ID') != undefined ? true : false}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[30, 15]}>
        <Col sm={{ span: 24 }} xl={{ span: 12 }}>
          <Form.Item
            name='country'
            initialValue={'Australia'}
          >
            <Input
              label="Country"
              type="select"
              options={coutryOptions}
              onChange={(value: any) => {
                const stateOptions = CountryInfo.getStateOptions(value)
                setStateOptions(stateOptions)
                setSuburbOptions([])
                // empty state and suburb value
                form.setFieldsValue({
                  state: '',
                  suburb: '',
                  country: value,
                })
              }}
              disabled={form.getFieldValue('business_ID') != '' && form.getFieldValue('business_ID') != undefined ? true : false}
              showSearch
              allowClear
            />
          </Form.Item>
        </Col>
        <Col sm={{ span: 24 }} xl={{ span: 12 }}>
          <Form.Item
            name='state'
          >
            <Input
              label="State"
              type="select"
              options={stateOptions}
              onChange={(value: any) => {
                const suburbOptions = CountryInfo.getCityOptions(value, form.getFieldValue('country'))
                setSuburbOptions(suburbOptions)
                // empty suburb value
                form.setFieldsValue({
                  suburb: '',
                })
              }}
              disabled={form.getFieldValue('business_ID') != '' && form.getFieldValue('business_ID') != undefined ? true : false}
              showSearch
              allowClear
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[30, 15]}>
        <Col sm={{ span: 24 }} xl={{ span: 12 }}>
          <Form.Item
            name='suburb'
          >
            <Input label="Suburb/City" type="select" options={suburbOptions} showSearch allowClear
              disabled={form.getFieldValue('business_ID') != '' && form.getFieldValue('business_ID') != undefined ? true : false}
            />
          </Form.Item>
        </Col>
        <Col sm={{ span: 24 }} xl={{ span: 12 }}>
          <Form.Item
            name='postcode'
            rules={[VALIDATION_RULES.MAX.POSTCODE]}
          >
            <Input label="Postcode" type="number" style={{ width: '100%' }}
              disabled={form.getFieldValue('business_ID') != '' && form.getFieldValue('business_ID') != undefined ? true : false}
            />
          </Form.Item>
        </Col>
      </Row> */}

    </Form>
  ) : (
    <Form
      form={form}
      name='address-info'
      onFinish={onFinish}
    >
      <Row gutter={[30, 15]}>
        <Col sm={{ span: 24 }} xl={{ span: 24 }}>
          <Form.Item
            name='type'
          >
            <Input
              label="Address Type"
              type="select"
              options={renderAddressTypeOption('address')}
              showSearch
              placeholder='Select a address type (optional)'
              disabled={true}
              allowClear
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[30, 15]}>
        <Col sm={{ span: 24 }} xl={{ span: 24 }}>
          <AddressSearchInputFormItem
            form={form}
            inputProps={{
              placeholder: 'Enter Address',
              label: 'Address',
              required: true,
              disabled: form.getFieldValue('business_ID') != '' && form.getFieldValue('business_ID') != undefined ? true : false
            }}
            formItemProps={{
              name: 'full_address',
              rules: [{ required: true, message: 'Please enter the Address.' }]
            }}
            fromAddressFieldNames={'default'}
            notShowManualCheckBox={form.getFieldValue('business_ID') != '' && form.getFieldValue('business_ID') != undefined ? true : false}
            // isManualInput={payload?.is_manual_input}
            isManualInput={form.getFieldValue('business_ID') != '' && form.getFieldValue('business_ID') != undefined ? false : form.getFieldValue('is_manual_input')}
          />
        </Col>
      </Row>
      {/* <Row gutter={[30, 15]}>
        <Col sm={{ span: 24 }} xl={{ span: 24 }}>
          <AddressSearchInput
            handleAddressChange={(address: any) => {
              setAddressState({
                unit: address.unit,
                street: address.street,
                suburb: address.city,
                state: address.state,
                postcode: address.postcode,
                country: address.country,
                full_address: address.full_address,
              })
            }}
            addressValue={addressState?.full_address}// full address
            disabled={form.getFieldValue('business_ID') != '' && form.getFieldValue('business_ID') != undefined ? true : false}
          />
        </Col>
      </Row> */}
      {/* <Row gutter={[30, 15]}>
        <Col sm={{ span: 24 }} xl={{ span: 12 }}>
          <Form.Item
            name='unit'
            rules={[VALIDATION_RULES.MAX.STREET]}
          >
            <Input label="Unit/Suite" type="text"
              disabled={form.getFieldValue('business_ID') != '' && form.getFieldValue('business_ID') != undefined ? true : false}
            />
          </Form.Item>
        </Col>
        <Col sm={{ span: 24 }} xl={{ span: 12 }}>
          <Form.Item
            name='street'
            rules={[VALIDATION_RULES.MAX.STREET]}
          >
            <Input label="Street Address" type="text"
              disabled={form.getFieldValue('business_ID') != '' && form.getFieldValue('business_ID') != undefined ? true : false}
            />
          </Form.Item>
        </Col>

      </Row>
      <Row gutter={[30, 15]}>
        <Col sm={{ span: 24 }} xl={{ span: 12 }}>
          <Form.Item
            name='country'
          >
            <Input
              label="Country"
              type="select"
              options={coutryOptions}
              onChange={(value: any) => {
                const stateOptions = CountryInfo.getStateOptions(value)
                setStateOptions(stateOptions)
                setSuburbOptions([])
              }}
              disabled={form.getFieldValue('business_ID') != '' && form.getFieldValue('business_ID') != undefined ? true : false}
              showSearch
              allowClear
            />
          </Form.Item>
        </Col>
        <Col sm={{ span: 24 }} xl={{ span: 12 }}>
          <Form.Item
            name='state'
          >
            <Input
              label="State"
              type="select"
              options={stateOptions}
              onChange={(value: any) => {
                const suburbOptions = CountryInfo.getCityOptions(value, form.getFieldValue('country'))
                setSuburbOptions(suburbOptions)
              }}
              disabled={form.getFieldValue('business_ID') != '' && form.getFieldValue('business_ID') != undefined ? true : false}
              showSearch
              allowClear
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[30, 15]}>
        <Col sm={{ span: 24 }} xl={{ span: 12 }}>
          <Form.Item
            name='suburb'
          >
            <Input label="Suburb/City" type="select" options={suburbOptions} showSearch allowClear
              disabled={form.getFieldValue('business_ID') != '' && form.getFieldValue('business_ID') != undefined ? true : false}
            />
          </Form.Item>
        </Col>
        <Col sm={{ span: 24 }} xl={{ span: 12 }}>
          <Form.Item
            name='postcode'
            rules={[VALIDATION_RULES.MAX.POSTCODE]}
          >
            <Input label="Postcode" type="number" style={{ width: '100%' }}
              disabled={form.getFieldValue('business_ID') != '' && form.getFieldValue('business_ID') != undefined ? true : false}
            />
          </Form.Item>
        </Col>
      </Row> */}


    </Form>
  )
}