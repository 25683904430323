export const EVENT_TYPE = [
  "ObjectEvent",
  "TransactionEvent",
  "AggregationEvent",
  "TransformationEvent",
  "AssociationEvent",
];

// Event Type list
export const EVENT_TYPE_LIST = {
  ObjectEvent: "ObjectEvent",
  TransactionEvent: "TransactionEvent",
  AggregationEvent: "AggregationEvent",
  TransformationEvent: "TransformationEvent",
  AssociationEvent: "AssociationEvent",
};

export const EVENT_TYPES = {
  ObjectEvent: {
    label: "Object Event",
    value: "ObjectEvent",
  },
  TransactionEvent: {
    label: "Transaction Event",
    value: "TransactionEvent",
  },
  AggregationEvent: {
    label: "Aggregation Event",
    value: "AggregationEvent",
  },
  TransformationEvent: {
    label: "Transformation Event",
    value: "TransformationEvent",
  },
  AssociationEvent: {
    label: "Association Event",
    value: "AssociationEvent",
  },
};

export const EVENT_ACTION = {
  add: {
    label: "ADD",
    value: "ADD",
  },
  delete: {
    label: "DELETE",
    value: "DELETE",
  },
  observe: {
    label: "OBSERVE",
    value: "OBSERVE",
  },
};

export const EVENT_BIZ_STEP = {
  shipping: {
    label: "shipping",
    value: "shipping",
  },
  receiving: {
    label: "receiving",
    value: "receiving",
  },
  commissioning: {
    label: "commissioning",
    value: "commissioning",
  },
  packing: {
    label: "packing",
    value: "packing",
  },
  unpacking: {
    label: "unpacking",
    value: "unpacking",
  },
  loading: {
    label: "loading",
    value: "loading",
  },
  unloading: {
    label: "unloading",
    value: "unloading",
  },
  killing: {
    label: "killing",
    value: "killing",
  },
};

export const BUSINESS_STEP = ["shipping", "receiving"];

export const BUSINESS_TRANSACTION_LABEL = {
  po: "Purchase Order",
  inv: "Invoice",
};

export const EPCIS_VOCAB_TYPE = [
  {
    label: "Business Transaction Type",
    value: "Business Transaction Type",
  },
  // {
  //   label: "Business Location",
  //   value: "Business Location",
  // },
  {
    label: "Business Step",
    value: "Business Step",
  },
  {
    label: "Disposition",
    value: "Disposition",
  },
  {
    label: "Error Reason",
    value: "Error Reason",
  },
  // {
  //   label: "Read Point",
  //   value: "Read Point",
  // },
  {
    label: "Source Destination Type",
    value: "Source Destination Type",
  },
];

export const DISPLAY_STATS_BACKGROUND_COLOR = {
  BLUE: {
    numberBg: `var(--gradient-07, linear-gradient(147deg, #73DFE7 0%, #0063F7 100%))`,
    textBg: `var(--primary-subtle, #CDF)`,
  },
  ORANGE: {
    numberBg: `linear-gradient(133deg, #FD371F 0%, #FF844B 100%)`,
    textBg: `#FFDCCA`,
  },
  PURPLE: {
    numberBg: `var(--simple-37, linear-gradient(125deg, #B39FFF 0%, #6A1ED2 100%))`,
    textBg: `var(--purple-purple-4, #FFE5FF)`,
  },
  GREEN: {
    numberBg: `var(--simple-35, linear-gradient(0deg, #009EFD 0%, #2AF598 100%))`,
    textBg: `var(--foundation-green-g-50, #E6FAE6)`,
  },
  MIX: {
    numberBg: `var(--gradient-06, linear-gradient(147deg, #FF3B3B 0%, #60C 100%))`,
    textBg: ` #FFE9E9`,
  },
  BROWN: {
    numberBg: `var(--simple-196, linear-gradient(148deg, #DBA17A 0%, #C9927F 100%))`,
    textBg: `#FFE6DD`,
  },
};
