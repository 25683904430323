import { Collapse, Descriptions } from 'antd';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { formatTitle, initialDateTimeValue } from 'utilities/Functions/FormatFunctions';
import EventInfoModalInfo from './EventDetailInfoModal';
import linkVctIcon from '../../../../../statics/images/linkVctIcon.svg';
import { FlexWrapper } from 'statics/styles/StyledComponents';
import { convertEPCToBusinessLanguage, getLatAndLng, isPetunaData } from '../../Utils/functions';
import { BUSINESS_TRANSACTION_LABEL, EVENT_BIZ_STEP, EVENT_TYPES } from '../../Utils/CONSTANTS';
import { useFileUpload } from 'utilities/hooks';
import { useAuthStore } from 'zustand-stores';

const isHowVisible = ['shipping', 'receiving']
type Props = {
  selectedEvent: any;
  toggleSelection: any;
  eventModalClose?: any;
}
const { Panel } = Collapse;

const CollapseData = [
  {
    type: 'Event Identification',
  },
  {
    type: 'Event Type',
  },
  {
    type: 'What',
  },
  {
    type: 'When',
  },
  {
    type: 'Where',
  },
  {
    type: 'Why',
  },

];


const EventCollapse = (props: Props) => {
  const { selectedEvent, toggleSelection, eventModalClose } = props
  const [collapse, setCollapse] = useState<any>([...CollapseData])
  const collapseControl = useCollapseControl([...collapse])
  const activeKeys = collapseControl.collapseActiveKeys

  console.log('EventCollapse selectedEvent aaa', selectedEvent)

  useEffect(() => {
    // ? check needs to show HOW
    if (selectedEvent) {
      const readableProductData = selectedEvent?.ilmd?.['itrazo:extension']?.productInfo
      if (readableProductData?.businessStep) {
        if (isHowVisible.includes(readableProductData?.businessStep.toLowerCase())) {
          setCollapse([...CollapseData, { type: 'How' }, { type: 'Compliance' }])
        } else {
          setCollapse([...CollapseData, { type: 'Compliance' }])
        }
      }
    }

  }, [selectedEvent])


  return (
    <>
      {
        Array.isArray(collapse) && collapse.map((item: any, index: number) => {
          const eventHeaderName = item.type
          return (
            <CollapseContainer key={index}>
              <Collapse activeKey={activeKeys?.[index]}
                onChange={() => collapseControl.toggleCollapse(index)}
              >
                <Panel
                  header={
                    <div style={{ paddingTop: '3px' }}>
                      {formatTitle(eventHeaderName)}
                    </div>
                  }
                  key={1}
                >
                  <RenderContent
                    eventHeaderName={eventHeaderName}
                    keyValue={index}
                    collapseData={collapse}
                    toggleSelection={toggleSelection}
                    selectedEvent={selectedEvent}
                    eventModalClose={eventModalClose}
                  />
                </Panel>
              </Collapse>
            </CollapseContainer>
          )
        })
      }
    </>
  )
}

export default EventCollapse

const RenderContent = (props: { eventHeaderName: string, keyValue: number, collapseData: any, toggleSelection: any, selectedEvent: any, eventModalClose: any }) => {
  const { toggleSelection, keyValue, selectedEvent, eventModalClose, eventHeaderName } = props

  console.log('eventHeaderName', eventHeaderName, keyValue,)

  const [fileHandler] = useFileUpload();

  const isAcgUser = useAuthStore.getState().isAcgUser
  const isAccUser = useAuthStore.getState().isAccUser

  const PACK_TYPE = isPetunaData(isAcgUser, isAccUser) ? ['Pallet', 'Case', 'Pack', 'Batch'] : ['Animal', 'Livestock', 'Carcase', 'Primary Cut', 'Carton']

  const readableProductData = selectedEvent?.ilmd?.['itrazo:extension']?.productInfo

  const isShowEPCList = selectedEvent?.type === EVENT_TYPES.ObjectEvent.value || selectedEvent?.type === EVENT_TYPES.TransactionEvent.value
  const isShowParentEPCList = selectedEvent?.type === EVENT_TYPES.AggregationEvent.value
  const isShowChildEPCList = selectedEvent?.type === EVENT_TYPES.AggregationEvent.value || selectedEvent?.type === EVENT_TYPES.AssociationEvent.value
  const isShowInputOutputList = selectedEvent?.type === EVENT_TYPES.TransformationEvent.value

  const parentEPCList = readableProductData?.lotInfo?.filter((lot: any) => {
    return selectedEvent?.parentID === lot?.epc
  });

  const inputEPCList = readableProductData?.lotInfo?.filter((lot: any) => {
    return selectedEvent?.inputEPCList?.some((epc: any) => epc?.epc === lot?.epc)
  });


  const childEPCList = readableProductData?.lotInfo?.filter((lot: any) => {
    if (selectedEvent?.type === EVENT_TYPES.TransformationEvent.value) {
      return selectedEvent?.inputEPCList?.length > 0 && selectedEvent?.inputEPCList?.some((epc: any) => epc?.epc !== lot?.epc)
    } else if (selectedEvent?.type === EVENT_TYPES.AssociationEvent.value) {
      return selectedEvent?.childEPCs?.some((epc: any) => epc?.epc === lot?.epc)
    } else {
      return selectedEvent?.parentID !== lot?.epc
    }
  });

  console.log('inputEPCListaaaa', inputEPCList, childEPCList, parentEPCList)

  const [eventHappenModal, setEventHappenModal] = useState<any>({
    open: false,
    name: '',
    info: [],
    isButtonVisible: false,
    itemData: null
  });

  const onCloseModalInfo = () => {
    setEventHappenModal({
      open: false,
      name: '',
      info: [],
      isButtonVisible: false,
      itemData: null
    })
  };

  const onEventsHappenClick = (info: any, name: string, isButtonShow?: boolean, item?: any) => {
    setEventHappenModal({
      ...eventHappenModal,
      open: true,
      name: name,
      info: info,
      isButtonVisible: isButtonShow,
      itemData: item
    })
  };

  const toSourceOrDestinationInformationValues = (item: any, type: string) => {

    return [
      {
        label: 'ID',
        value: `${item[type]}` || 'N/A'
      },
      {
        label: 'Type',
        value: item.type || 'N/A'
      },
    ];
  }

  const toEPCInformationValues = (singleLotInfo?: any, index?: number) => {


    // const animalData = selectedEvent?.extension?.animalDetails 
    const animalData = readableProductData

    let Values: any = []

    const renderLabel = (packType: string) => {
      let label: string = ''
      switch (packType) {
        case 'Pallet':
          label = `${packType} ID`
          break;
        case 'Case':
          label = `${packType} ID`
          break;
        case 'Pack':
          label = `${packType} ID`
          break;
        case 'Batch':
          label = `${packType} ID`
          break;
        case 'Animal':
          label = `${packType} ID`
          break;
        case 'Livestock':
          label = `${packType} ID`
          break;
        case 'Carcase':
          label = `${packType} ID`
          break;
        case 'Primary Cut':
          label = `${packType} ID`
          break;
        case 'Carton':
          label = `${packType} ID`
          break;
        default:
          break;
      }

      return label
    }

    const extraInfoForAnimal = [
      {
        label: 'Gender',
        value: animalData?.gender ? animalData?.gender : ''
      },
      {
        label: 'Ear Tag',
        value: animalData?.earTag ? animalData?.earTag : ''
      },
      {
        label: 'Establishment Number',
        value: animalData?.establishmentNumber || 'N/A'
      },
      {
        label: 'Barcode',
        value: animalData?.barcode ? animalData?.barcode : ''
      },
    ]

    if (PACK_TYPE.includes(singleLotInfo?.packType)) {
      Values = [
        {
          label: 'EPC',
          value: singleLotInfo?.epc
        },
        {
          label: 'Type',
          value: singleLotInfo?.packType
        },
        {
          label: renderLabel(singleLotInfo?.packType),
          value: singleLotInfo?.packID
        },
        {
          label: 'Batch/Lot Number',
          value: singleLotInfo?.productLot
        },
        {
          label: 'Quantity',
          value: singleLotInfo?.quantity
        },
        {
          label: 'Weight',
          value: singleLotInfo?.productWeight
        },
        {
          label: 'UoM',
          value: singleLotInfo?.uom
        },

      ];

      Values = isPetunaData(isAcgUser, isAccUser) ? Values : [...Values, ...extraInfoForAnimal]

    } else {
      if (isPetunaData(isAcgUser, isAccUser)) {

        Values = [
          {
            label: 'EPC',
            value: singleLotInfo?.epc
          },
          {
            label: 'Fish Group',
            value: singleLotInfo?.fishGroup
          },
          {
            label: 'Species',
            value: singleLotInfo?.species
          },
          {
            label: 'Catch Method',
            value: singleLotInfo?.catchMethod
          },
          {
            label: 'Harvest ID',
            value: singleLotInfo?.harvestID
          },
          {
            label: 'Harvest Date',
            value: initialDateTimeValue(singleLotInfo?.harvestStartDate)
          }
        ];
      }
      else {

        Values = [
          // {
          //   label: 'EPC',
          //   value: singleLotInfo?.epc || 'N/A'
          // },
          {
            label: 'Livestock ID',
            value: '3243546'
          },
          {
            label: 'Establishment Number',
            value: 'QKMM0474' || 'N/A'
          },
          {
            label: 'Gender',
            value: 'Male' || 'N/A'
          },
          {
            label: 'Ear Tag',
            value: 'N/A'
          },
          {
            label: 'Barcode',
            value: 'B#5462357'
          },
          {
            label: 'Regulatory Compliance',
            value: 'Brands Act, The Model Food Act, Food Act 2016' || 'N/A'
          }
        ];
      }
    }

    const filteredValues = Array.isArray(Values) && Values?.filter((item: any) => item.value !== '' && item.value !== null)

    return filteredValues

  }

  const getWhatDimension = (item?: any, index?: number) => {
    // if (!isPetunaData(isAcgUser, isAccUser)) {
    //   return (
    //     <>
    //       <Span onClick={() => onEventsHappenClick(toEPCInformationValues(item, index), 'EPC Information', true, item)}>
    //         {toggleSelection?.display_epcis_standard ? 'urn:epc:id:sgln:9999999999999.32626.951 000324488683' : `Animal (3243546)`}
    //       </Span>
    //       <img src={linkVctIcon} alt='Link Icon' />
    //     </>
    //   )
    // }


    if (!item) return

    const packType = item?.packType

    console.log('packTypeaaa', packType, PACK_TYPE, item)

    if (PACK_TYPE.includes(packType)) {
      return (
        <>
          <Span onClick={() => onEventsHappenClick(toEPCInformationValues(item, index), 'EPC Information', true, item)}>
            {packType === "Batch" ?
              <>
                {toggleSelection?.display_epcis_standard ? item?.epc : `${item?.packType} ${item?.productLot && `(${item?.productLot})`}`}
              </> :
              <>
                {toggleSelection?.display_epcis_standard ? item?.epc : `${item?.packType} ${item?.packID && `(${item?.packID})`}`}
              </>}
          </Span>
          <img src={linkVctIcon} alt='Link Icon' />
        </>
      )
    } else {
      return (
        <>
          <Span onClick={() => onEventsHappenClick(toEPCInformationValues(item, index), 'EPC Information', true, item)}>
            {/* {toggleSelection?.display_epcis_standard ? item?.epc : isPetunaData(isAcgUser, isAccUser) ? `Fish Group (${item?.fishGroup})` : 'Cattle'} */}
            {toggleSelection?.display_epcis_standard ? item?.epc : isPetunaData(isAcgUser, isAccUser) ? `Fish Group (${item?.fishGroup})` : selectedEvent?.cteCode === "MP CTE5" ? 'Carcase (5648536)' : selectedEvent?.cteCode === "MP CTE6.1" ? 'Carton (45465466)' : 'Livestock(5745656)'}
          </Span>
          <img src={linkVctIcon} alt='Link Icon' />
        </>
      )
    }
  }

  const toTransactionValues = (item: any) => {
    if (!item) return ''
    const renderNumber = (id: string) => {
      let number = ''
      // split with : and get last one
      const splitId = id.split(':')
      if (splitId.length > 0) {
        number = splitId[splitId.length - 1]
      }

      return number
    };

    return [
      {
        label: 'Type',
        value: (BUSINESS_TRANSACTION_LABEL as any)[convertEPCToBusinessLanguage(item?.type)] || 'N/A'
      },
      {
        label: 'Number',
        value: renderNumber(item?.id)
      },
      {
        label: 'URN',
        value: item?.id || 'N/A'
      },
    ];
  };

  const renderBizTransaction = (item: any, isDisplayEPCIS: boolean) => {
    if (!item) return ''
    return (
      <React.Fragment key={0}>
        <Span onClick={() => onEventsHappenClick(toTransactionValues(item), 'Business Transaction')}>
          {isDisplayEPCIS ? item?.id : `${(BUSINESS_TRANSACTION_LABEL as any)[convertEPCToBusinessLanguage(item?.type)]} (${convertEPCToBusinessLanguage(item?.id)})`}
        </Span>
        <img src={linkVctIcon} alt='Link Icon' />
      </React.Fragment>
    )
  };

  const renderComplianceDocs = (s3Key: any) => {
    if (!s3Key) return '--'

    // from fileName, split and get last one, and return <a> with href
    const splitName = s3Key?.split('/')

    const fileName = splitName[splitName.length - 1]

    return (
      <React.Fragment key={0}>
        <a href={fileHandler.constructS3Url(s3Key)} target="_blank" rel="noreferrer">{fileName}</a>
      </React.Fragment>
    )
  };

  const contentArray = [
    {
      key: 'Event Identification',
      data: <>
        <Descriptions.Item label={`CTE Code`}>{`${selectedEvent?.cteCode}`}</Descriptions.Item>
        <Descriptions.Item label={`Event ID`}>{toggleSelection?.display_epcis_standard ? selectedEvent?.eventID : readableProductData?.intEventID}</Descriptions.Item>
        <Descriptions.Item label={`Original Document ID`}> {readableProductData?.originalExtDocID || 'N/A'}</Descriptions.Item>
      </>,
    },
    {
      key: 'Event Type',
      data: <>
        <Descriptions.Item label={`Event Type`}>{selectedEvent?.type}</Descriptions.Item>
        <Descriptions.Item label={`Action`}>{selectedEvent?.action}</Descriptions.Item>
      </>,
    },
    {
      key: 'What',
      data: <>
        {/* {isPetunaData(isAcgUser, isAccUser) ? */}
        <>
          {isShowParentEPCList && <Descriptions.Item label={`Parent Id`}>{Array.isArray(parentEPCList) && parentEPCList.map((item: any, index: number) => {
            return (
              <>
                {getWhatDimension(item, index)}
              </>
            )
          })}</Descriptions.Item>}
          {isShowEPCList && <Descriptions.Item label={'EPC List'}>{ }</Descriptions.Item>}
          {isShowChildEPCList && <Descriptions.Item label={'Child List'}>{ }</Descriptions.Item>}
          {isShowInputOutputList && <Descriptions.Item label={`Input EPC List`}>{ }</Descriptions.Item>}
          {
            Array.isArray(inputEPCList) && inputEPCList?.map((item: any, index: number) => {
              return (
                <Descriptions.Item key={index} label={`${index + 1}.`}>
                  <FlexWrapper flexStart gap='6px'>
                    {getWhatDimension(item, index)}
                  </FlexWrapper>
                </Descriptions.Item>
              )
            })
          }
          {isShowInputOutputList && <Descriptions.Item label={`Output EPC List`}>{ }</Descriptions.Item>}
          {
            Array.isArray(childEPCList) && childEPCList?.map((item: any, index: number) => {
              return (
                <Descriptions.Item key={index} label={`${index + 1}.`}>
                  <FlexWrapper flexStart gap='6px'>
                    {getWhatDimension(item, index)}
                  </FlexWrapper>
                </Descriptions.Item>
              )
            })
          }
        </>
        {/* :
          <>
            <Descriptions.Item label={'EPC List'}>{ }</Descriptions.Item>
            <Descriptions.Item label={`1.`}>
              <FlexWrapper flexStart gap='6px'>
                {getWhatDimension()}
              </FlexWrapper>
            </Descriptions.Item>
          </>
        } */}
      </>,
    },
    {
      key: 'When',
      data: <>
        <Descriptions.Item label={`Event Time`}>{
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>AEST: {initialDateTimeValue(selectedEvent?.eventTime)}</span>
          </div>}</Descriptions.Item>
        <Descriptions.Item label={`Event Time Zone Offset`}>{selectedEvent?.eventTimeZoneOffset}</Descriptions.Item>
      </>,
    },
    {
      key: 'Where',
      data: <>
        <Descriptions.Item label={`Read Point`}>
          {toggleSelection?.display_epcis_standard ? selectedEvent?.readPoint?.id : getLatAndLng(selectedEvent?.readPoint?.id || '')}
        </Descriptions.Item>
        <Descriptions.Item label={`Business Location`}>
          {toggleSelection?.display_epcis_standard ? selectedEvent?.bizLocation?.id : readableProductData?.currentbusinessLocation?.address || 'N/A'}
        </Descriptions.Item>
      </>,
    },
    {
      key: 'Why',
      data: <>
        <Descriptions.Item label={`Business Step`}>{toggleSelection?.display_epcis_standard ? selectedEvent?.bizStep : readableProductData?.businessStep || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label={`Disposition`}>{toggleSelection?.display_epcis_standard ? selectedEvent?.disposition : convertEPCToBusinessLanguage(selectedEvent?.disposition)}</Descriptions.Item>

        {readableProductData?.businessStep === EVENT_BIZ_STEP.shipping.value && (
          <>
            <Descriptions.Item label={`Business Transactions List`}>
            </Descriptions.Item>
            {Array.isArray(selectedEvent?.bizTransactionList) && selectedEvent?.bizTransactionList?.length > 0 && selectedEvent?.bizTransactionList?.map((item: any, index: number) => {
              if (!item?.id) return
              return (
                <Descriptions.Item key={index} label={`${index + 1}.`}>
                  {renderBizTransaction(item, toggleSelection?.display_epcis_standard)}
                </Descriptions.Item>
              )
            })}
          </>
        )}

        {!isPetunaData(isAcgUser, isAccUser) ? (<>
          <Descriptions.Item label={`Source`}>{
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>Location</span>
              {
                Array.isArray(selectedEvent?.sourceList) && selectedEvent?.sourceList?.length > 0 ?
                  <>
                    {
                      selectedEvent?.sourceList?.map((item: any, index: any) => (
                        <>
                          {
                            item?.source ? (
                              <React.Fragment key={index}>
                                <FlexWrapper flexStart gap='6px'>
                                  <Span key={index}
                                    onClick={() => {
                                      onEventsHappenClick(toSourceOrDestinationInformationValues(item, 'source'), 'Source Information')
                                    }}
                                  >{toggleSelection?.display_epcis_standard ? item.source :
                                    readableProductData?.currentbusinessLocation?.businessName
                                    }</Span>
                                  <img src={linkVctIcon} alt='Link Icon' />
                                </FlexWrapper>
                              </React.Fragment>
                            ) : <span>N/A</span>
                          }
                        </>
                      ))
                    }
                  </>
                  :
                  <span>N/A</span>
              }
            </div>
          }</Descriptions.Item>
          <Descriptions.Item label={`Destination`}>{
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>Location</span>
              {
                Array.isArray(selectedEvent?.destinationList) && selectedEvent?.destinationList?.length > 0 ?
                  <>
                    {selectedEvent?.destinationList?.map((item: any, index: any) => (
                      <>
                        {
                          item?.destination ? (
                            <React.Fragment key={index}>
                              <FlexWrapper flexStart gap='6px'>
                                <Span key={index}
                                  onClick={() => {
                                    onEventsHappenClick(toSourceOrDestinationInformationValues(item, 'destination'), 'Destination Information')
                                  }}
                                >{toggleSelection?.display_epcis_standard ? item.destination : readableProductData?.currentbusinessLocation?.businessName}</Span>
                                <img src={linkVctIcon} alt='Link Icon' />
                              </FlexWrapper>
                            </React.Fragment>
                          ) : <span>N/A</span>
                        }
                      </>
                    ))}
                  </>
                  :
                  <span>N/A</span>
              }
            </div>
          }</Descriptions.Item></>) : (<>
            <Descriptions.Item label={`Source`}>{
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>Location</span>
                {
                  Array.isArray(selectedEvent?.sourceList) && selectedEvent?.sourceList?.length > 0 ?
                    <>
                      {
                        selectedEvent?.sourceList?.map((item: any, index: any) => (
                          <>
                            {
                              item?.source ? (
                                <React.Fragment key={index}>
                                  <FlexWrapper flexStart gap='6px'>
                                    <Span key={index}
                                      onClick={() => {
                                        onEventsHappenClick(toSourceOrDestinationInformationValues(item, 'source'), 'Source Information')
                                      }}
                                    >{toggleSelection?.display_epcis_standard ? item.source :
                                      readableProductData?.sourcebusinessLocation?.[index]?.businessName
                                      }</Span>
                                    <img src={linkVctIcon} alt='Link Icon' />
                                  </FlexWrapper>
                                </React.Fragment>
                              ) : <span>N/A</span>
                            }
                          </>
                        ))
                      }
                    </>
                    :
                    <span>N/A</span>
                }
              </div>
            }</Descriptions.Item><Descriptions.Item label={`Destination`}>{
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>Location</span>
                {
                  Array.isArray(selectedEvent?.destinationList) && selectedEvent?.destinationList?.length > 0 ?
                    <>
                      {selectedEvent?.destinationList?.map((item: any, index: any) => (
                        <>
                          {
                            item?.destination ? (
                              <React.Fragment key={index}>
                                <FlexWrapper flexStart gap='6px'>
                                  <Span key={index}
                                    onClick={() => {
                                      onEventsHappenClick(toSourceOrDestinationInformationValues(item, 'destination'), 'Destination Information')
                                    }}
                                  >{toggleSelection?.display_epcis_standard ? item.destination : readableProductData?.destinationbusinessLocation?.[index]?.businessName}</Span>
                                  <img src={linkVctIcon} alt='Link Icon' />
                                </FlexWrapper>
                              </React.Fragment>
                            ) : <span>N/A</span>
                          }
                        </>
                      ))}
                    </>
                    :
                    <span>N/A</span>
                }
              </div>
            }</Descriptions.Item>

          </>)}

      </>
    },
    {
      key: 'How',
      data: <>
        <Descriptions.Item label={`Sensor Element`}>{`No sensor data`}</Descriptions.Item>
        {/* <Descriptions.Item label={`Device ID`}>{toggleSelection?.display_epcis_standard ? selectedEvent?.sensorElementList[0]?.sensorMetadata?.deviceID : splitGetLastOne(selectedEvent?.sensorElementList[0]?.sensorMetadata?.deviceID)}</Descriptions.Item>
       {
         selectedEvent?.sensorElementList[0]?.sensorReport?.map((item: any, index: number) => {
           return (<Descriptions.Item key={index} label={`${item?.type}`}>
             {
               <div style={{ display: 'flex', flexDirection: 'column' }}>
                 <span>{`${item?.value} ${item?.uom}`}</span>
               </div>
             }
           </Descriptions.Item>
           )
         })
       } */}
      </>,
    },
    {
      key: 'Compliance',
      data: <>
        {!isPetunaData(isAcgUser, isAccUser) && (
          <>
            <Descriptions.Item label='Safe Food Audits'>{`${(selectedEvent?.cteCode === "FL CTE9.1" || selectedEvent?.cteCode === "FL CTE9.2") ? 'N/A' : 'PASS'}`}</Descriptions.Item>
            <Descriptions.Item label='Regulatory Compliance'>{(readableProductData?.regulatory_compliance || readableProductData?.regulatoryCompliance) ? (readableProductData?.regulatory_compliance || readableProductData?.regulatoryCompliance) : 'N/A'}</Descriptions.Item >
          </>
        )}
        {Array.isArray(selectedEvent?.submitted) && selectedEvent?.submitted?.length > 0 ?
          <Descriptions.Item label='Document Type'>{'Document Name'}</Descriptions.Item>
          :
          <Descriptions.Item>{'No compliance document attached'}</Descriptions.Item>
        }
        {Array.isArray(selectedEvent?.submitted) && selectedEvent?.submitted?.length > 0 && selectedEvent?.submitted?.map((item: any, index: number) => {
          return (
            <Descriptions.Item key={index} label={`${item?.certificate_Type}`}>
              {renderComplianceDocs(item?.file_name)}
            </Descriptions.Item>
          )
        })
        }
      </>
    },
  ]

  return (
    <>
      {
        contentArray.map((item: any) => {
          if (item.key === eventHeaderName) {
            return (
              <StyledDescriptions
                column={1}
                layout="horizontal"
                bordered
              >
                {item?.data}
              </StyledDescriptions>
            )
          }
        })
      }
      <EventInfoModalInfo
        open={eventHappenModal?.open}
        onClose={onCloseModalInfo}
        name={eventHappenModal?.name}
        info={eventHappenModal?.info}
        isButtonVisible={eventHappenModal?.isButtonVisible}
        eventModalClose={eventModalClose}
        itemData={eventHappenModal?.itemData}
      />
    </>
  )
};

const useCollapseControl = (eventsdata: any[]) => {
  const [collapseActiveKeys, setCollapseActiveKeys] = useState<number[]>([1])  // 1: expand; 0: collapse;
  useEffect(() => {
    let activeKeys: number[] = []
    if (eventsdata.length > 1) {
      activeKeys = Array(eventsdata.length).fill(1)
    } else {
      activeKeys = [1]
    }
    setCollapseActiveKeys([...activeKeys])
  }, [eventsdata.length])
  // expand or collapse a single collapse
  const toggleCollapse = (index: any) => {
    const activeKeys = [...collapseActiveKeys]
    // set the active key to opposite of what it is
    activeKeys[index] = activeKeys[index] === 0 ? 1 : 0
    setCollapseActiveKeys([...activeKeys])
  }
  return {
    collapseActiveKeys,
    setCollapseActiveKeys,
    toggleCollapse,
  }
}

const CollapseContainer = styled.div`
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
  .ant-collapse {
    border:  1px solid #8F90A6 !important;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    background: #30324A !important;
    font-size: 14px !important;
  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: none !important;
  }
  .ant-collapse-header-text {
      width: 100% !important;
      padding-top: 3px;
  }
  .ant-collapse-item .ant-collapse-content > .ant-collapse-content-box {
    background: #28293D !important;
    padding: 10px 1px !important;
    border: none !important;
  }
  
`;

const StyledDescriptions = styled(Descriptions)`
  padding-bottom: 10px;
    .ant-descriptions-title {
      color: var(--white-text-color) !important;
    }
    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      background-color: transparent !important;
      padding: 6px 12px !important;
    }
    .ant-descriptions-bordered,
    .ant-descriptions-view {
      border: unset !important;
    }
    .ant-descriptions-bordered,
    .ant-descriptions-item-label {
      border-right: unset !important;
      color: var(--dark-dark-4, #C7C9D9) !important;
      font-size: 12px !important;
      padding: 6px 15px !important;
      width: 200px !important;
    }
    .ant-descriptions-bordered,
    .ant-descriptions-item-content {
      color: var(--light-light-2, #F2F2F5) !important;
      font-size: 12px !important;
    }
    .ant-descriptions-bordered,
     .ant-descriptions-row {
      border-bottom: 2px dashed #383A56 !important;
    }
  
   `;

const Span = styled.span`
 cursor: pointer;
   &:hover {
    color: var(--primary-color) !important;
    text-decoration: underline !important;
  }
    `;