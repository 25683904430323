import { create } from 'zustand'

type State = {
  isGlobalLoading: boolean
  isEmailSyncing: boolean
}

type Action = {
  setIsGlobalLoading: (isLoading: boolean) => void
  setIsEmailSyncing: (isEmailSyncing: boolean) => void
}

export type GeneralStore = State & Action

const initialState: State = {
  isGlobalLoading: false,
  isEmailSyncing: false
}

/**
 * Zustand store for managing the general state
 */
const useGeneralStore = create<GeneralStore>((set) => ({
  ...initialState,
  setIsGlobalLoading: (isGlobalLoading: boolean) => {
    set({ isGlobalLoading })
  },
  setIsEmailSyncing: (isEmailSyncing: boolean) => {
    set({ isEmailSyncing })
  }
}))

export default useGeneralStore