import { CRM_API } from "utilities/AdiApi";
import { logError } from '../utilities/functions'
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";


const UserAPI = {
  getUserNameById: async (id: string) => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API.get(`${bizCode}/User?GET_ID&user_ID=${id}`)
      return res.data.Items[0]?.full_name
    } catch (e) {
      logError(e)
      throw e
    }

  },
  getUserById: async (id: string) => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API.get(`${bizCode}/User?GET_ID&user_ID=${id}`)
      return res.data.Items[0]
    } catch (e) {
      logError(e)
      throw e
    }

  },
  getAllUsers: async () => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API.get(`${bizCode}/User`)
      return res.data.Items
    } catch (e) {
      logError(e)
      throw e
    }
  },
}

export default UserAPI