import { CRM_API } from "utilities/AdiApi";
import { logError } from '../utilities/functions'
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";
import { useUserStore } from "zustand-stores";

const SyncEmailAPI = {
  syncEmails: async (accessToken: string) => {
    const bizCode = getBizCode()
    const username = useUserStore.getState().username
    const userEmail = useUserStore.getState().email
    try {
      await CRM_API.post(`${bizCode}/Email_sync`, {
        user_id: username,
        user_email: userEmail,
        access_token: accessToken,
      })
    } catch (e) {
      logError(e)
      throw e
    }
  },
  logEmail: async (payload: any) => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API.put(`${bizCode}/Email_sync`, payload)
      return res
    } catch (e) {
      logError(e)
      throw e
    }
  },
  deleteEmail: async (datetime: string, objectId: string, subject: string) => {
    const bizCode = getBizCode()
    const username = useUserStore.getState().username
    try {
      await CRM_API.delete(`${bizCode}/Email_sync?datetime=${datetime}&object_id=${objectId}&subject=${subject}&deleted_by=${username}`)
    } catch (e) {
      logError(e)
      throw e
    }
  }
}

export default SyncEmailAPI