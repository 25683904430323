import React, { useState, useEffect } from 'react'
import { LogEmail, SimpleStyledTable, EmailLogHeader } from '../../components';
import { message, Skeleton } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { useCRMMsal } from '../../hooks';
import { formatTitle, initialDateTimeValue, } from 'utilities/Functions/FormatFunctions';
import { TABLE_CELL_CONFIG } from 'utilities/CONSTANTS';
import { useAuthStore, useGeneralStore } from 'zustand-stores';
import { AuthStore } from 'zustand-stores/useAuthStore';
import useSyncEmailStore, { SyncEmailStore } from 'zustand-stores/useSyncEmailStore';
import { SyncEmailAPI } from '../../apis';
import { ADI_CONFIRM } from 'utilities/Functions/GlobalHelperFunctions';
import { GeneralStore } from 'zustand-stores/useGeneralStore';



type Props = {
  objectId: string;
  object: 'company' | 'contact'
}

const Emails = (props: Props) => {
  const { objectId, object } = props;
  const isCrmReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.crm.isReadOnly)
  const [modal, setModal] = useState({
    open: false,
    viewOnly: false,
    emailData: null
  })

  const [msalAction] = useCRMMsal()

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  const syncEmailStore = useSyncEmailStore((state: SyncEmailStore) => state)
  const emails = syncEmailStore.emailsData[objectId] || []

  const [filteredData, setFilteredData] = useState<any[]>([])


  // console.log('syncEmailStore', syncEmailStore, emails, filteredData);

  // Email data are stored in s3 and fetched from there
  useEffect(() => {
    syncEmailStore.getEmailsFromS3(objectId)
  }, [])

  useEffect(() => {
    setFilteredData(syncEmailStore.emailsData[objectId] || [])
  }, [syncEmailStore.emailsData])


  const handleSyncEmailLogin = async () => {
    // get access token from microsoft api
    const key = 'syncEmail'
    try {
      // get access token from microsoft api
      const token = await msalAction.loginToGetToken()
      if (token) {
        await syncEmailStore.syncEmails(token, objectId)
      }

    } catch (e) {
      // console.log(e);
      message.error({ content: 'Failed to sync emails', key })
    }

  }

  const handleSyncEmailSilent = async () => {
    try {
      const token = await msalAction.getSilentToken()
      if (token) {
        await syncEmailStore.syncEmails(token, objectId)
      }
    } catch (e) {
      console.log('handleSyncEmailSilent', e);
    }
  }

  const onEmailSearch = (value: string) => {
    let result = [...emails]

    if (value) {
      // Search for recipient names, subjects and contents
      result = result.filter((email: any) => {
        // Filter by names
        const names = email?.attendies_list?.names
        const filteredByNames = names?.some((name: string) => name.toLowerCase().includes(value.toLowerCase()))

        // Filter by subject
        const filteredBySubject = email?.subject.toLowerCase().includes(value.toLowerCase())

        // FIlter by contents
        // Remove html tags from contents
        const contents = email?.contents?.replace(/<[^>]*>/g, '')
        const filteredByContents = contents.toLowerCase().includes(value.toLowerCase())

        return filteredByNames || filteredBySubject || filteredByContents
      })
    }

    setFilteredData(result)
  }

  const deleteEmail = async (email: any) => {
    ADI_CONFIRM({
      actionTitle: `delete this email`,
      isdelete: true,
      onConfirm: async () => {
        setIsGlobalLoading(true)
        try {
          await SyncEmailAPI.deleteEmail(email.datetime, objectId, email.subject)
          message.success('Email deleted successfully')
          syncEmailStore.getEmailsFromS3(objectId)
        } catch (e) {
          console.log('deleteEmail', e);
          message.error('Failed to delete email')
        } finally {
          setIsGlobalLoading(false)
        }
      },
    })

  }

  const tableActionColumn = isCrmReadOnly ? [] : [
    {
      title: formatTitle('ACTIONS'),
      dataIndex: 'actions',
      key: 'actions',
      ...TABLE_CELL_CONFIG,
      align: 'center',
      width: 80,
      render: (text: string, record: any) => {
        return (
          <DeleteOutlined
            style={{ fontSize: '14px' }}
            onClick={(e) => {
              e.stopPropagation()
              deleteEmail(record)
            }}
          />
        )
      }
    }
  ]

  const emailTableColumns = [
    {
      title: formatTitle('DATE & TIME'),
      dataIndex: 'datetime',
      key: 'datetime',
      ...TABLE_CELL_CONFIG,
      width: 170,
      render: initialDateTimeValue
    },
    {
      title: formatTitle('RECIPIENTS'),
      dataIndex: 'attendies_list',
      key: 'attendies_list',
      ...TABLE_CELL_CONFIG,
      width: 200,
      render: (att: any) => {
        const names = att?.names
        console.log('names', names);
        return Array.isArray(names) && names?.join(', ')
      }
    },
    {
      title: formatTitle('SUBJECT'),
      dataIndex: 'subject',
      key: 'subject',
      ...TABLE_CELL_CONFIG,
      width: 'auto',
    },
    ...tableActionColumn
  ]


  const handleViewEmail = (record: any) => {
    console.log('record', record);
    setModal({ ...modal, open: true, viewOnly: true, emailData: record })
  }


  const renderEmailTable = () => {
    if (!Array.isArray(filteredData) || filteredData?.length === 0) {
      return (
        <div style={{ color: 'white', textAlign: 'center', fontSize: '1.5rem', marginTop: '30px' }}>
          No Emails to Display.
        </div>
      )
    }

    return (
      <SimpleStyledTable
        tableData={[...filteredData]}
        columns={emailTableColumns}
        onRowClick={handleViewEmail}
      />
    )
  }

  return (

    <div style={{ color: 'white' }}>
      <EmailLogHeader
        onEmailSearch={onEmailSearch}
        handleSyncEmailLogin={handleSyncEmailLogin}
        handleSyncEmailSilent={handleSyncEmailSilent}
        onLogEmailClick={() => setModal({ ...modal, open: true, viewOnly: false })}
      />
      {
        syncEmailStore.isFetching && emails?.length === 0 ?
          <Skeleton active paragraph={{ rows: 8 }} />
          : renderEmailTable()
      }


      <LogEmail
        open={modal.open}
        onCancel={() => setModal({ ...modal, open: false, viewOnly: false, emailData: null })}
        object={object}
        objectId={objectId}
        viewOnly={modal.viewOnly}
        reload={() => syncEmailStore.getEmailsFromS3(objectId)}
        emailData={modal.emailData}
      />

    </div>

  )
}

export default Emails;







