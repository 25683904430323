import { useEffect } from 'react'
import { getBizCode } from 'utilities/Functions/GlobalHelperFunctions'
import { useWebSocket } from 'utilities/hooks'
import { useAuthStore, useUserStore } from 'zustand-stores'
import { AuthStore } from 'zustand-stores/useAuthStore'
import useSyncEmailStore, { SyncEmailStore } from 'zustand-stores/useSyncEmailStore'
import { UserStore } from 'zustand-stores/useUserStore'

/**
 * A component that listens to global state change and manage the state data accordingly.
 * It's rendered in App/index.tsx
 */
const GlobalDataControl = () => {
  const bizCode = getBizCode()
  const username = useAuthStore((state: AuthStore) => state.username)
  const hasCrmAccess = useAuthStore((state: AuthStore) => state.moduleAccess.crm.hasAccess)
  const fetchCurrentUser = useUserStore((state: UserStore) => state.fetchCurrentUser)
  const getEmailStorageInfo = useSyncEmailStore((state: SyncEmailStore) => state.getEmailStorageInfo)

  const { socketData, distributeSocketEvent } = useWebSocket()

  // Fetch current user data
  useEffect(() => {
    if (username && bizCode) {
      fetchCurrentUser(bizCode, username)
    }
  }, [username, bizCode])

  // Listen to live websocket data
  useEffect(() => {
    if (socketData) {
      distributeSocketEvent(socketData.msg_type, socketData.data)
    }
  }, [socketData])

  // Fetch CRM email storage info if the user has CRM access
  useEffect(() => {
    if (hasCrmAccess && username) {
      getEmailStorageInfo()
    }
  }, [username, hasCrmAccess])


  return null
}

export default GlobalDataControl

