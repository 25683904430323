import React, { useState, } from 'react'
import { loginConfig } from 'microsoftAuthConfig'
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import "bootstrap/dist/css/bootstrap.min.css";
import { msalInstance } from 'index';
import axios from 'axios';
import { message } from 'antd';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';


/**
 * Microsoft authentication for the functionality of syncing emails from outlook
 */
const useCRMMsal = () => {

  const { accounts } = useMsal()

  const isAuthenticated = useIsAuthenticated();



  console.log('accounts', accounts, isAuthenticated);


  /**
   * Get token by opening the login popup
   */
  const loginToGetToken = async () => {

    let token = ''
    try {
      const res = await msalInstance.loginPopup(loginConfig)
      console.log('getAccessToken', res);
      if (res?.accessToken) {
        token = res.accessToken
      };
    } catch (e) {
      console.log('getAccessToken', e);
    }

    return token
  }

  /**
   * Get token without opening the login popup
   */
  const getSilentToken = async (args?: { disabledWarning?: boolean }) => {
    const { disabledWarning } = args || {}
    let token = ''
    let response: any = null
    if (accounts?.length > 0) {
      response = msalInstance.acquireTokenSilent(loginConfig)
    } else {
      if (!disabledWarning) {
        message.warning('Please log in on the Email logs page to sync emails')
      }
    }

    try {
      const res = await response
      console.log('getAccessToken', res);
      if (res?.accessToken) {
        token = res.accessToken
      };
    } catch (e) {
      console.log('getAccessToken', e);
    }


    return token
  }

  const msalAction = {
    getSilentToken,
    loginToGetToken
  }



  return [msalAction] as const
}

export default useCRMMsal