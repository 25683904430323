/**
 * The Navigation Sidebar for desktop devices.
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import styled from 'styled-components';
import { Page } from '../../../utilities/types';
import Utils from "../../../utils/Utils";
import { RightCircleFilled, LeftCircleFilled, CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Button } from '../../../statics/styles/StyledComponents';


import { Tooltip } from 'antd';
import { capitaliseFirst } from 'utilities/Functions/FormatFunctions';
import { getBizCode, getSessionStorage } from "utilities/Functions/GlobalHelperFunctions";
import { SESSION_STORAGE_KEYS } from 'utilities/CONSTANTS';
import shortLogo from "../../../statics/images/ShortLogo.png";
import collapseOn from "../../../statics/images/Collapse_On_Menu.svg";
import expandeOn from "../../../statics/images/Expand_On_Menu.svg";
import customActive from "../../../statics/images/customerOnboard.svg";
import { useAuthStore } from 'zustand-stores';

interface RouterType extends RouteComponentProps<any> {
  location: any;
}

type Props = {
  logo: string;
  pages: Page[];
  pagesExternal: Page[];
  fold: boolean;
  setFold: Function;
} & RouterType;




const DesktopNavigation = (props: Props) => {

  const [showSubNav, setShowSubNav] = useState(false);
  const [menuCollapsed, setMenuCollapsed] = useState(true);
  const {
    logo, pages, location
  } = props;



  const access_level_str = getSessionStorage(SESSION_STORAGE_KEYS.ACCESS_LEVEL, true)


  const BIZ_CODE = getBizCode()
  // initialise module page tab and page view, clear module session storage when switch between modules
  // so it would always go to the default (first) page


  // hide sub nav when folded
  useEffect(() => {
    if (props.fold) {
      setShowSubNav(false)
    }
  }, [props.fold])

  const TooltipContainer = (tooltipProps: any) => {
    const { title, children } = tooltipProps
    if (props.fold) {
      return (
        <Tooltip title={capitaliseFirst(title)} placement="right">
          {children}
        </Tooltip>
      )
    }
    return (
      <>
        {children}
      </>
    )

  }

  return (
    <NavigationDisplay className="">
      {/* <TopDisplayWrapper className='mrg-md'> // removed the margin class, added padding class because need to add border-bottom */}
      <TopDisplayWrapper className='pd-md'
      // fold={props.fold}
      >
        <CollapseButton style={{ paddingBottom: '13px' }}
          className="toggle-menu-btn"
          type="button"
          onClick={() => {
            props.setFold(!props.fold);
            setMenuCollapsed(!menuCollapsed);
          }}
        >
          {
            props.fold && <Img src={shortLogo} style={{ width: '24px', paddingTop: "8px" }} alt="navigation logo" />
          }
        </CollapseButton>
        {menuCollapsed && <Img src={expandeOn} style={{ width: "20px" }}
          onClick={() => {
            props.setFold(!props.fold);
            setMenuCollapsed(!menuCollapsed);
          }} className='collapsed-icon' />}
        <NavigationImageWrapper fold={props.fold}>
          <Img className="sidebar-logo pad-xl-top pad-xl-bottom mrg-2xl-bottom" src={logo} alt="navigation logo" />
        </NavigationImageWrapper>
        {!menuCollapsed && <Img src={collapseOn} style={{ width: "20px" }}
          onClick={() => {
            props.setFold(!props.fold);
            setMenuCollapsed(!menuCollapsed);
          }} className={"expanded-icon"} />}
      </TopDisplayWrapper>
      <ItemsWrapper>
        {pages.map((page) => {
          const isActive = Utils.isSameOrSubPath(location.pathname, page.path);

          return (
            <div key={page.key}>
              <TooltipContainer title={page.name}>
                <NavigationItem
                  className={"block mrg-0" + (isActive ? "active" : "")} // removed the pd-5 class
                  key={page.key}
                  to={page.path}
                >
                  <div
                    className="sidebar-menu-item pad-md min-height-3xl flex align-items-center"
                    style={{ position: 'relative' }}
                  >
                    <span className="text-xl line-height-xl mrg-xs-right icon">
                      {isActive && page.activeIcon ? page.activeIcon : page.icon}
                    </span>
                    <span className="text-ml line-height-2xl title sidebartitles" style={{ color: isActive ? 'var(--primary-color)' : 'var(--side-panel-text-color)', marginLeft: `${props.fold ? '2px' : '0px'}` }}>
                      <>
                        {page.name}
                      </>
                    </span>
                    {
                      Array.isArray(page.subNav) && page.subNav.length > 0 &&
                      <div
                        style={{ position: 'absolute', left: '-11px' }}
                      >
                        {
                          showSubNav ? <CaretDownOutlined style={{ color: 'var(--white-text-color)', fontSize: '10px' }}
                            onClick={
                              (e) => {
                                console.log('arrow clicked!!', showSubNav, e)
                                e.preventDefault()
                                if (Array.isArray(page.subNav)) setShowSubNav(!showSubNav)
                              }
                            }
                          /> : <CaretRightOutlined style={{ color: 'var(--white-text-color)' }}
                            onClick={
                              (e) => {
                                console.log('arrow clicked', showSubNav, e)
                                e.preventDefault()
                                if (Array.isArray(page.subNav)) setShowSubNav(!showSubNav)
                              }
                            }
                          />

                        }
                      </div>
                    }

                  </div>
                </NavigationItem>
              </TooltipContainer>
              {
                page.subNav && page.subNav.map((page: any) => {
                  const isActive = Utils.isSameOrSubPath(location.pathname, page.path);
                  if (isActive && !showSubNav) {
                    // show sub nav if it's active
                    setShowSubNav(true)
                  }
                  if (!showSubNav) {
                    return null
                  }
                  return (
                    <TooltipContainer title={`${page.name}`}>
                      <NavigationItem className={"block mrg-0  " + (isActive ? "sub-active" : "")}
                        key={page.key}
                        to={page.path}
                      >
                        <div className="sidebar-menu-item pad-md min-height-3xl flex align-items-center">
                          {/* <span className="text-xl line-height-xl mrg-xs-right"></span> */}
                          <span className="text-xl line-height-xl mrg-xs-right icon">
                            {isActive && page.activeIcon ? page.activeIcon : page.icon}
                          </span>
                          <span className="text-ml line-height-2xl title sidebartitles" style={{ color: isActive ? 'var(--primary-color)' : 'var(--side-panel-text-color)', marginLeft: `${props.fold ? '2px' : '0px'}` }}>
                            {`${page.name}`}</span>
                        </div>
                      </NavigationItem>
                    </TooltipContainer>
                  )
                })
              }
            </div>
          )
        }
        )}
        {BIZ_CODE !== 'acg' && BIZ_CODE !== 'acc' && BIZ_CODE !== 'tco' ?
          <div className="sidebar-menu-item pad-md min-height-3xl flex align-items-center" >
            {/* <span className="text-xl line-height-xl mrg-xs-right"></span> */}
            <a href={process.env.REACT_APP_CUSTOMER_ONBOARDING_LINK} target='_blank' style={{ paddingLeft: '6px' }}>
              <span className="text-xl line-height-xl mrg-xs-right icon">
                <img src={customActive} />
              </span>
              <span className="text-ml line-height-2xl title sidebartitles" style={{ color: 'var(--side-panel-text-color)', marginLeft: `${props.fold ? '2px' : '0px'}` }}>
                Customer Onboarding</span>
            </a>
          </div>
          : null}

      </ItemsWrapper>

    </NavigationDisplay >
  );
};

export default withRouter(DesktopNavigation);

const NavigationDisplay = styled.div`
  height: 100%;
  width: 100%;
  background: var(--side-bar-color);
  // box-shadow: 6px 0px 4px rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const NavigationImageWrapper = styled.div`
  max-width: 100px;
  padding-left: 16px;
  // margin: auto;
  ${({ fold, ...props }: { fold: boolean;[prop: string]: any }) => {
    if (fold) {
      return `visibility:hidden`
    }
  }}
`;

const ItemsWrapper = styled.div`
  // overflow-y: auto;
  width: 100%;
  height: 100%;
  ${(props) => {
    if (!props.theme.mobile) {
      return `
      // overflow-y: auto;
      ${props.theme.myScrollbar}
    `;
    }
    return '';
  }}
`;

const Img = styled.img`
  display: block;
  width: 100%;
`;

const NavigationItem = styled(Link)`
  position: relative;
  overflow: hidden;
  text-decoration: none;
  color: white;
  cursor: pointer;
  border-radius: 0px;
  font-weight: 400;
  height:68px;
  &>div{
    margin: auto 0;
  }


  &:hover{
      text-decoration: none;
      color: white;
      &:not(.active) {
        background-color: #28293d;
      }

      &.active {
        .icon {
          color: var(--primary-color);
        }
      }
  }
  span{
      display: inline-block;
      &:first-child{
        width: 3rem;
      }
  }

  &:not(.active) {
    border-left: 5px solid transparent;  // remove the border but keep the spacing
  }

  &.active {
    background: #28293d;
    // border-left: 5px solid var(--primary-color); // remove the border color
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25), 0px -2px 4px rgba(0, 0, 0, 0.25);
  }
  &.sub-active {
    background: #28293d;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25), 0px -2px 4px rgba(0, 0, 0, 0.25);
  }


`;

const CollapseButton = styled(Button)`
  font-size: 2rem;
  // margin-top: 10px;
  // margin-left: -5px;
`;

const TopDisplayWrapper = styled.div`
  display: flex;
  // justify-content: space-between;
  // align-items: center;
  align-items: center;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`