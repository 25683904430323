import React, { useCallback, useEffect, useState, MouseEvent } from "react";
import { ActionButton, MergeableTableCellP, DisplayEmptyTextPrimary, DisplayEmptyTextSecondary } from "../../../../../statics/styles/StyledComponents";
import { message } from "antd";
import AdiTable from "components/AdiTable";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { ADI_CONFIRM } from "utilities/Functions/GlobalHelperFunctions";
import { MasterDataHeader } from "../../components";
import AddPresetModal from "./AddPresetModal";
import CustomFieldModal from "../../components/CustomFieldModal";
import { formatTitle } from "utilities/Functions/FormatFunctions";


import FlowInfoPanel from "components/FlowInfoPanel";
import { PM_PRESET_FLOW_STEPS } from "utilities/CONSTANTS";
import MasterDataAPI from "../../apis/MasterDataAPI";
import DropdownAPI from "../../apis/DropdownAPI";
import { useGeneralStore } from "zustand-stores";
import { GeneralStore } from "zustand-stores/useGeneralStore";
import useAuthStore, { AuthStore } from 'zustand-stores/useAuthStore';


type Props = {};

interface PmRuleDataType {
	class?: string;
	refID?: string;
	apply_to: any[];
	apply_to_refId: any[];
	maitenance_preset_name: string;
	maintenance_rules: maintenanceRules[];
}

interface maintenanceRules {
	type: string,
	name: string,
	frequency: string,
	frequencyDropdown: string,
	reminder: boolean,
	comments: string,
	attributes_to_collect: any[],
}

const PmRules = () => {
	const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
	const isAmReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.am.isReadOnly)
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [tableData, setTableData] = useState<any>([]);
	const [filteredTableData, setFilteredTableData] = useState<any>([]);
	const [pmRulesFullData, setPmRulesFullData] = useState<any>([]);
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [isViewMode, setIsViewMode] = useState<boolean>(false);

	const [customFieldModal, setCustomFieldModal] = useState<any>({
		open: false,
		modalData: {
			fieldName: '',
			fieldType: '',
			helper: '',
			required: false,
		},
		isEdit: false,
		itemIndex: null,
		arrayIndex: null,
	});
	const [customFields, setCustomFields] = useState<{
		fieldName: string,
		fieldType: string,
		helper: string,
		required: boolean,
	}[][]>([]);  // An array of arrays


	const [pmRuleData, setPmRuleData] = useState<PmRuleDataType>({
		class: "MAINTENANCE",
		refID: "",
		apply_to: [],
		apply_to_refId: [],
		maitenance_preset_name: "",
		maintenance_rules: [{
			type: "",
			name: "",
			frequency: "",
			frequencyDropdown: "",
			reminder: false,
			comments: "",
			attributes_to_collect: [{   // custom fields
				fieldName: '',
				fieldType: '',
				helper: '',
				required: false,
			}],
		}],
	});

	;

	const getTableData = async () => {
		try {
			setIsLoading(true)
			const result = await MasterDataAPI.getMasterType('MAINTENANCE')
			if (result && result.length > 0) {
				setTableData(result)
				setFilteredTableData(result)
				setPmRulesFullData(result);
			} else {
				message.info('No result found')
				setTableData([])
				setFilteredTableData([])
				setPmRulesFullData([]);
			}
			// setIsGlobalLoading(false)

		} catch (err) {
			console.log('getTableData master fail...', err)
			// setIsGlobalLoading(false)
		} finally {
			setIsLoading(false)
		}

	}

	const initialize = useCallback(
		() => { getTableData() },
		[],
	)
	useEffect(initialize, [])

	const handleModalOpen = async (e: any, curRecord?: any) => {
		e.preventDefault();
		setIsGlobalLoading(true)
		console.log("openModal");

		try {
			const result = await DropdownAPI.getDropdowns({ "asset_presets": true })
			const { asset_presets } = result
			console.log('asset_presets', asset_presets)
			let finalAssetData: any = {}
			// edit current record
			if (curRecord && curRecord.refID) {
				console.log('curRecord', curRecord)
				setIsEdit(true);
				let finalSingleRec: any = {}
				Array.isArray(pmRulesFullData) && pmRulesFullData.length > 0 && pmRulesFullData.map((singleMainRecord: any) => {

					if (singleMainRecord.refID == curRecord.refID) {

						console.log('matchedrecord', singleMainRecord)

						const newRecord = singleMainRecord.maintenance_rules.map((rulesItem: any, index: number) => {

							///// frequency
							var array: boolean[] = [...trueFrqValue]
							var truefrequency = true
							let finalFrequency: any = '';
							let finalfrequencyDropdown: any = '';
							if (rulesItem.frequency == 'On Receival' || rulesItem.frequency == '') {
								finalfrequencyDropdown = rulesItem.frequency;
								truefrequency = false;
							} else {
								const splitFrequency = rulesItem.frequency.split('(');
								finalFrequency = splitFrequency[0]
								const subSplit = splitFrequency[1].split(')')
								finalfrequencyDropdown = subSplit[0]

								if (index >= array.length) {
									array.push(truefrequency)
								} else {
									array[index] = truefrequency
								}
							}
							console.log('finalFrequency', finalFrequency, finalfrequencyDropdown, array)
							setTrueFrqValue([...array])

							/// customer fields
							const custom_fields: { [key: string]: string | boolean }[] = []
							const custAttributesKeys = Object.keys(rulesItem.attributes_to_collect)
							// console.log('custAttributesKeys', custAttributesKeys)

							custAttributesKeys.map((key: string) => {
								const arr = rulesItem.attributes_to_collect[key]

								arr.map((item: any) => {
									// item format == {filedName: {helper: string, required: boolean}}
									const fieldName = Object.keys(item)[0]
									const helper = item[fieldName].helper
									const required = item[fieldName].required

									if (fieldName) {
										custom_fields.push({
											fieldName,
											fieldType: key,
											helper,
											required
										})
									}
								})
							})



							console.log('custom_fields', custom_fields)

							return { ...rulesItem, attributes_to_collect: custom_fields, frequency: finalFrequency, frequencyDropdown: finalfrequencyDropdown }

						})
						console.log('newRecord', newRecord)
						finalSingleRec = {
							...singleMainRecord, apply_to_refId: asset_presets,
							maintenance_rules: [...newRecord]
						}

						console.log('newRecord finalSingleRec', finalSingleRec)
						finalAssetData = { ...finalSingleRec }
					}
				})
			} else {
				finalAssetData = { ...pmRuleData, apply_to_refId: asset_presets }
			}

			setPmRuleData(finalAssetData)
			setIsModalOpen(true)
			setIsGlobalLoading(false)
		} catch (err) {
			console.log('handleModalOpen fail...', err)
			setIsGlobalLoading(false)
		}
	};

	const handleModalClose = (e?: MouseEvent) => {
		if (e) {
			e.preventDefault();
		}
		setIsModalOpen(false);
		setIsViewMode(false);
		setIsEdit(false);
		setCustomFields([])

		setPmRuleData({
			class: "MAINTENANCE",
			refID: "",
			apply_to: [],
			apply_to_refId: [],
			maitenance_preset_name: "",
			maintenance_rules: [{
				type: "",
				name: "",
				frequency: "",
				frequencyDropdown: "",
				reminder: false,
				comments: "",
				attributes_to_collect: [{
					fieldName: '',
					fieldType: ''
				}],
			}],
		})
	};

	const handleConfirm = async (values: any) => {

		setIsModalOpen(false);
		console.log("pmRuleData", values, pmRuleData && pmRuleData.refID);
		const { apply_to, maintenance_rules, maitenance_preset_name } = values
		let formatRules: any[] = []
		if (maintenance_rules.length > 0) {
			formatRules = maintenance_rules.map((item: any, index: number) => {
				let formatFrequency: string = ''
				if (item.frequencyDropdown == 'On Receival') {
					formatFrequency = `${item.frequencyDropdown}`
				} else {
					formatFrequency = `${item.frequency}(${item.frequencyDropdown})`
				}



				const custom_attributes: { [key: string]: any } = {
					int: [],
					str: [],
					datetime: [],
					bool: [],
					doc: []
				}

				console.log('new custom fields', customFields)


				customFields.length > 0 && customFields[index].map((rec: any) => {
					const fieldType = rec.fieldType
					if (fieldType !== "") {
						// custom_attributes[fieldType].push(rec.fieldName)
						custom_attributes[fieldType].push({ [rec.fieldName]: { helper: rec.helper, required: rec.required } })
					}
				})

				return {
					attributes_to_collect: custom_attributes,
					comments: item.comments,
					frequency: formatFrequency,
					name: item.name,
					reminder: item.reminder,
					type: item.type,
				}

			})
		}
		console.log('formatRules', formatRules)


		const isEdit = pmRuleData.refID && pmRuleData.refID.length > 0 ? true : false
		const payload = {
			class: "MAINTENANCE",
			refID: isEdit ? pmRuleData.refID : "",
			apply_to: apply_to,
			maitenance_preset_name: maitenance_preset_name,
			maintenance_rules: formatRules,
		}
		console.log('payload', payload)

		setIsGlobalLoading(true)

		try {
			let res: any
			if (isEdit) {
				res = await MasterDataAPI.updateMaster(payload)
			} else {
				res = await MasterDataAPI.addMaster(payload)
			}

			console.log('handleConfirm res', res)
			setIsGlobalLoading(false)
			handleModalClose()
			message.success(`${isEdit ? 'Updated' : 'Added'} maintenance rule successfully, thank you`)
			getTableData()

		} catch (err) {
			console.log('Add maintenance data fail...', err)
			message.error(err.message)
			setIsGlobalLoading(false)
		}

	};

	const validateMessages = {
		required: 'Please enter ${label}.',
		types: {
			email: 'Please provide a valid ${label}.',
			number: '${label} is not a valid number.',
		},
	};



	const [trueFrqValue, setTrueFrqValue] = useState<boolean[]>([]);
	const frequencyHandler = (e: any, index: number) => {
		console.log('index==.', index, e)
		var values: boolean[] = [...trueFrqValue]
		var value = true
		if (e === 'On Receival' || e === '') {
			value = false;
		}
		if (index >= values.length) {
			values.push(value)
		} else {
			values[index] = value
		}
		setTrueFrqValue([...values])
	};

	const handleDelete = async (record: any) => {
		console.log('deleterecord', record)
		const { refID, } = record
		try {
			setIsGlobalLoading(true)
			const res = await MasterDataAPI.deleteMaster(refID, record.class)
			console.log('handleDelete res', res)
			setIsGlobalLoading(false)

			message.success(`Delete PM rule data successfully, thank you`)
			getTableData()
		} catch (err) {
			console.log('DeletePM rule data fail...', err)
			message.error(err.message)
			setIsGlobalLoading(false)
		}


	}



	const renderMergeableTableCell = (text: any, record: any, index: number, cellDataKey: string) => {
		return {
			props: { style: { padding: 0, } },
			children: record.maintenance_rules && record.maintenance_rules.length > 0 ? (
				<>
					{record.maintenance_rules.map((item: any, idx: number) => {
						if (cellDataKey == 'name') {
							return (
								<MergeableTableCellP
									key={item[cellDataKey]}
									style={{ borderBottom: (idx == record.maintenance_rules.length - 1) ? 'none' : '1px solid #555555' }}
								>
									{`${item[cellDataKey]}`}
								</MergeableTableCellP>
							)

						} else {
							return (
								<MergeableTableCellP
									key={item[cellDataKey]}
									style={{ borderBottom: (idx == record.maintenance_rules.length - 1) ? 'none' : '1px solid #555555' }}
								>
									{
										cellDataKey === 'reminder' ?
											// render boolean value
											<>
												{item[cellDataKey] ? 'Yes' : 'No'}
											</>
											:
											`${item[cellDataKey]}`
									}

								</MergeableTableCellP>
							);
						}


					})}
				</>
			) : (
				<MergeableTableCellP >
					---
				</MergeableTableCellP>
			)
		}
	}

	console.log('data', tableData)

	const columns: any = [
		{
			title: formatTitle("No."),
			dataIndex: "key",
			align: "left",
			render: (text: any, record: any, index: number) => {
				return <>{index + 1}</>
			}
		},
		{
			title: formatTitle("PM Preset"),
			dataIndex: "maitenance_preset_name",
			align: "left",
			key: "maitenance_preset_name",
		},
		{
			title: formatTitle("Name"),
			dataIndex: "maintenance_rules",
			align: "left",
			key: "maintenance_rules",
			render: (text: any, record: any, index: number) => {
				return renderMergeableTableCell(text, record, index, 'name')
			},
		},
		{
			title: formatTitle("PM Type"),
			dataIndex: "maintenance_rules",
			align: "left",
			key: "maintenance_rules",
			render: (text: any, record: any, index: number) => {
				return renderMergeableTableCell(text, record, index, 'type')
			},
		},
		{
			title: formatTitle("Frequency"),
			dataIndex: "maintenance_rules",
			align: "left",
			key: "maintenance_rules",
			render: (text: any, record: any, index: number) => {
				return renderMergeableTableCell(text, record, index, 'frequency')
			},
		},
		{
			title: formatTitle("Reminder"),
			dataIndex: "maintenance_rules",
			align: "left",
			key: "maintenance_rules",
			render: (text: any, record: any, index: number) => {
				return renderMergeableTableCell(text, record, index, 'reminder')
			},
		},
		// {
		//     title: "Comments",
		//     dataIndex: "maintenance_rules",
		//     align: "center",
		//     key: "maintenance_rules",
		// },
		{
			title: formatTitle("Action"),
			// dataIndex: "asset_type",
			align: "left",
			// key: "maitenance_preset_name",
			// onCell: (_: any, index: number) => {
			//     for (let i of duplicateKeys) {
			//         if (i.length === 1) continue;
			//         if (index === i[0]) return { rowSpan: i.length }
			//         for (let j of i.slice(1)) {
			//             if (index === j) return { rowSpan: 0 }
			//         }
			//     }
			//     return {}
			// },
			render: (text: any, record: any, index: number) => (
				<>
					<p style={{ marginBottom: "0" }}>
						<ActionButton
							title="Edit"
							onClick={(e: MouseEvent) => {
								e.stopPropagation()
								setIsViewMode(false)
								handleModalOpen(e, record)
							}}
						>
							<EditOutlined />
						</ActionButton>
						<ActionButton
							title="Delete"
							onClick={(e: MouseEvent) => {
								e.preventDefault()
								e.stopPropagation()
								ADI_CONFIRM({
									customizeTitle: 'This action will delete the rules permanently and all associated data, are you sure to delete?',
									isdelete: true,
									onConfirm: () => {
										console.log('Delete')
										// setIsModalOpen(true)
										handleDelete(record)
									}
								})

							}}
						>
							<DeleteOutlined />
						</ActionButton>
					</p>
				</>
			),
		},
	];
	isAmReadOnly && columns.pop()

	// search from table data
	const searchByPresetName = (searchText: string) => {
		const filteredData = tableData.filter((item: any) => {
			return item.maitenance_preset_name.toLowerCase().includes(searchText.toLowerCase())
		})
		setFilteredTableData(filteredData)

	}

	const closeCustomField = () => {
		setCustomFieldModal({
			...customFieldModal,
			open: false,
			modalData: null,
			isEdit: false,
			itemIndex: null
		})
	}

	return (
		<>
			<div style={{ margin: '10px 0px 0px 0px' }}>
				<FlowInfoPanel
					items={[...PM_PRESET_FLOW_STEPS]}
					style={{ maxWidth: '600px', marginLeft: '16px' }}
				/>
			</div>
			{/* <ImgDiv2>
				<img src={assetPMPreset} alt='assetPMPreset' style={{ width: '100%' }} />
			</ImgDiv2> */}
			<MasterDataHeader
				searchPlaceholder="Search by Preset Name"
				createBtnText="+ New PM Rule"
				createOnClick={handleModalOpen}
				onSearch={searchByPresetName}
			/>
			{
				!isLoading && tableData.length == 0 ?
					<DisplayEmptyTextPrimary style={{ marginTop: '200px' }}>
						Start managing your PM Presets.
						<DisplayEmptyTextSecondary style={{ width: 'auto', marginTop: '30px' }}>
							Build, plan and schedule everything needed to keep your asset running smoothly.
						</DisplayEmptyTextSecondary>
					</DisplayEmptyTextPrimary >

					:
					<AdiTable
						loading={isLoading}
						tableData={filteredTableData}
						columns={columns}
						fullWidth
						marginTop="20px"
						onRowClick={(record: any, rowIndex: any, e: any) => {
							setIsViewMode(true)
							handleModalOpen(e, record)
						}}
					/>
			}
			<AddPresetModal
				isEdit={isEdit}
				isViewMode={isViewMode}
				isModalOpen={isModalOpen}
				handleModalClose={handleModalClose}
				pmRuleData={pmRuleData}
				handleConfirm={handleConfirm}
				validateMessages={validateMessages}
				frequencyHandler={frequencyHandler}
				trueFrqValue={trueFrqValue}
				setCustomFieldModal={setCustomFieldModal}
				customFields={customFields}
				setCustomFields={setCustomFields}
			/>
			<CustomFieldModal
				open={customFieldModal.open}
				modalData={customFieldModal.modalData}
				setCustomFields={setCustomFields}
				isEdit={customFieldModal.isEdit}
				onClose={closeCustomField}
				arrayIndex={customFieldModal.arrayIndex}
				itemIndex={customFieldModal.itemIndex}
			/>
		</>
	);
};


export default PmRules;


