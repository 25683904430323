/**
 * This file defines all api functions associated with Record API
 */

import { CRM_API } from "utilities/AdiApi";
import { IRecordPayload } from "../statics/types";
import { logError } from '../utilities/functions'
import { ContactType } from "../statics/types";
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";
import { useUserStore } from "zustand-stores";


// Error handling is in the component that calls the api function
const RecordAPI = {
  getRecordsByBusIdAndType: async (businessId: string, type: ContactType) => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API.get(`${bizCode}/Record?business_ID=${businessId}&contact_through=${type}`)
      return res.data.Items
    } catch (e) {
      logError(e)
      throw e
    }

  },
  getRecordsByContactIdAndType: async (contactId: string, type: ContactType) => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API.get(`${bizCode}/Record?contact_ID=${contactId}&contact_through=${type}`)
      return res.data.Items
    } catch (e) {
      logError(e)
      throw e
    }

  },
  getRecordById: async (id: string) => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API.get(`${bizCode}/Record?GET_ID&record_ID=${id}`)
      return res.data.Items[0]
    } catch (e) {
      logError(e)
      throw e
    }

  },
  createRecord: async (payload: IRecordPayload) => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API({
        method: 'POST',
        url: `${bizCode}/Record`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },
  updateRecord: async (payload: IRecordPayload) => {
    const bizCode = getBizCode()
    try {
      const res = await CRM_API({
        method: 'PUT',
        url: `${bizCode}/Record`,
        data: payload
      })
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },
  deleteRecord: async (id: string) => {
    // Only for deleting meetings and calls
    const bizCode = getBizCode()
    const username = useUserStore.getState().username
    try {
      const res = await CRM_API.delete(`${bizCode}/Record?record_ID=${id}&deleted_by=${username}`)
      return res
    } catch (e) {
      logError(e)
      throw e
    }

  },
  filterByBusinessTextAndDate: async (id: string, type: ContactType, searchText: string, date: string) => {
    const bizCode = getBizCode()
    try {
      const queryStr = `${bizCode}/Record?business_ID=${id}&contact_through=${type}&search=${searchText}&by_day=${date}`
      const res = await CRM_API.get(queryStr)
      // console.log(res)
      return res.data.Items
    } catch (e) {
      logError(e)
      throw e
    }

  },
  filterByContactTextAndDate: async (id: string, type: ContactType, searchText: string, date: string) => {
    const bizCode = getBizCode()
    try {
      const queryStr = `${bizCode}/Record?contact_ID=${id}&contact_through=${type}&search=${searchText}&by_day=${date}`
      const res = await CRM_API.get(queryStr)
      // console.log(res)
      return res.data.Items
    } catch (e) {
      logError(e)
      throw e
    }

  },

  getEmailsFromOutlook: async (accessToken: string) => {
    const bizCode = getBizCode()
    const username = useUserStore.getState().username
    try {
      await CRM_API.get(`${bizCode}/Record?user_ID=${username}&code=${accessToken}`)
    } catch (e) {
      logError(e)
      throw e
    }

  },

  syncEmails: async (accessToken: string) => {
    const bizCode = getBizCode()
    const username = useUserStore.getState().username
    const userEmail = useUserStore.getState().email
    try {
      await CRM_API.post(`${bizCode}/Email_sync`, {
        user_id: username,
        user_email: userEmail,
        access_token: accessToken,
      })
    } catch (e) {
      logError(e)
      throw e
    }
  },
  deleteEmail: async (datetime: string, objectId: string, subject: string) => {
    const bizCode = getBizCode()
    const username = useUserStore.getState().username
    try {
      await CRM_API.delete(`${bizCode}/Email_sync?datetime=${datetime}&object_id=${objectId}&subject=${subject}&deleted_by=${username}`)
    } catch (e) {
      logError(e)
      throw e
    }
  }

}

export default RecordAPI;