import AdiTable from 'components/AdiTable'
import ColumnSelectButton from 'components/GlobalButtons/ColumnSelectButton'
import ExportButton from 'components/GlobalButtons/ExportButton'
import Input from 'components/Input'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { TABLE_CELL_CONFIG } from 'utilities/CONSTANTS'
import { capitaliseFirst, formatTitle, initialCellValue } from 'utilities/Functions/FormatFunctions'
import { includeColumn } from 'utilities/Functions/GlobalHelperFunctions'
import LogBookAPI from '../../apis/LogBookAPI'
import { Col, Row } from 'antd'

type Props = {
  active: boolean;
}

enum VISITOR_STATUS {
  EXPECTED = 'expected',
  CHECKED_IN = 'checked_in',
  CHECKED_OUT = 'checked_out',
}

const Visitors = (props: Props) => {
  const { active } = props
  const [tableData, setTableData] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [filters, setFilters] = useState<any>({
    searchText: '',
    filteredData: [],
    dateRange: ['', '']
  })

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<any[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
  const [exportedData, setExportedData] = React.useState<any[]>([]);

  const selectColumnOptions: any = [
    {
      text: formatTitle("Visitor Email"),
      valueKey: "email",
    },
    {
      text: formatTitle("Visitor Phone No"),
      valueKey: "phone_no",
    },
    {
      text: formatTitle("MDNA Status"),
      valueKey: "mnda_status",
    },
    {
      text: formatTitle("Background Check"),
      valueKey: "background_check",
    }
  ]

  const defaultTablesColumns = selectColumnOptions.map((item: any) => item.valueKey)
  const [selectedTableColumns, setSelectedTableColumns] = useState<any[]>(defaultTablesColumns);

  useEffect(() => {
    if (active) {
      getTableData()
    }
  }, [active])

  const getTableData = async () => {
    setIsLoading(true)
    await LogBookAPI.getAllLogs().then((Logs: any) => {
      console.log('LogsgetTableData', Logs)
      let tempTableData: any[] = []

      // get items that are "business"
      tempTableData = Logs.filter((item: any) => item?.purpose_of_visit === "Business")

      // add key to each row
      tempTableData = tempTableData.map((item: any, index: number) => ({
        ...item,
        key: index,
      }))

      console.log('tempTableData', tempTableData)
      applyAllFilters(filters.searchText, filters.dateRange, tempTableData)
      setTableData(tempTableData)

      if (Array.isArray(tempTableData) && tempTableData.length == 0) {
        setSelectedTableColumns([])
      }

    }).catch((err: any) => {
      console.log('err', err)
    }).finally(() => {
      setIsLoading(false)
    })

  }

  // search by visitor name and company name
  const handleSearch = (searchText: string, data?: any) => {
    console.log('handleSearch', searchText, data)

    const search = searchText || ''

    const dataSource = data || tableData
    console.log('dataSource', dataSource, search)
    if (!search) {
      return dataSource
    }
    console.log('search', search, dataSource)
    const filteredData = dataSource.filter((item: any) => {
      const full_name = `${item?.first_name} ${item?.last_name}`
      return full_name.toLowerCase().includes(search.toLowerCase()) ||
        item?.company.toLowerCase().includes(search.toLowerCase())
    })
    console.log('filteredData', filteredData)
    return filteredData
  }

  const onDateChange = (date: [string, string], dataSource?: any) => {
    console.log('date==>', date, dataSource)
    const tempData = dataSource || tableData
    if (!date) {
      return tempData
    }
    const startDate: string = date[0] != "" ? moment(date[0], 'DD/MM/YYYY').format('YYYY-MM-DD') : ''
    const endDate: string = date[1] != "" ? moment(date[1], 'DD/MM/YYYY').format('YYYY-MM-DD') : ''

    if (startDate != "" && endDate != "") {
      const filteredData = tempData.filter((rec: any) => {
        const reportedDate: string = moment(rec?.visit_date).format('YYYY-MM-DD') || ''
        // compare dates
        return reportedDate >= startDate && reportedDate <= endDate
      })
      console.log('filtered', filteredData)
      return filteredData
    }
    else {
      console.log('elsefiltered')
      return tempData
    }

  }

  const applyAllFilters = (searchText: string, dateRange: [string, string], dataSource?: any) => {
    console.log('applyAllFilters', searchText, dateRange, dataSource)
    const tempData = dataSource || tableData
    const result: any = handleSearch(searchText, tempData)
    console.log('result', result)
    const filterData = onDateChange(dateRange || filters.dateRange, result)
    console.log('result', result)
    setFilters((prev: any) => ({
      ...prev,
      filteredData: [...filterData],
    }))
  }

  const newVisitorColumns = visitorColumns.filter((item: any) => {
    if (includeColumn(selectedTableColumns, item.key)) {
      return item
    }
  })

  const newExtraColumns = extraColumns.filter((item: any) => {
    if (includeColumn(selectedTableColumns, item.key)) {
      return item
    }
  })

  const finalTableColumns = [
    ...tableColumns,
    ...newVisitorColumns,
    ...fixedColumns,
    ...newExtraColumns,
  ];


  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedRows(selectedRows)
      setSelectedRowKeys(selectedRowKeys)
      constructExportData(selectedRows)
    },
  };

  const constructExportData = (selectedRows: any) => {
    const tempExportData: any[] = []
    selectedRows.forEach((item: any) => {
      const tempObj: any = {}
      tempObj["Status"] = item?.status && capitaliseFirst(item?.status)
      tempObj["Invitation"] = item?.invitation && capitaliseFirst(item?.invitation)
      tempObj["Date"] = item?.visit_date && moment(item?.visit_date).format('DD/MM/YYYY')
      tempObj["Company"] = item?.company && capitaliseFirst(item?.company)
      tempObj["Name"] = item?.first_name && item?.last_name && capitaliseFirst(`${item?.first_name} ${item?.last_name}`)
      tempObj["Email"] = item?.email
      tempObj["Phone No"] = item?.phone_no
      tempObj["MDNA Status"] = item?.mnda_status && capitaliseFirst(item?.mnda_status)
      tempObj["Host"] = item?.host && capitaliseFirst(item?.host)
      tempObj["Check In"] = item?.check_in_time && moment(item?.check_in_time).format('HH:mm')
      tempObj["Check Out"] = item?.check_out_time && moment(item?.check_out_time).format('HH:mm')
      tempObj["Background Check Status"] = item?.induction_status && capitaliseFirst(item?.induction_status)
      tempExportData.push(tempObj)
    })
    setExportedData(tempExportData)
  };


  return (
    <>
      <Row gutter={[40, 15]} align="middle" justify='space-between'>
        <Col style={{ marginRight: "30px" }} >
          <Row gutter={[15, 15]} align="bottom">
            <Col>
              <Input
                placeholder="Search by Visitor's Name or Company Name"
                type='search'
                value={filters.searchText}
                onSearch={() => {
                  applyAllFilters(filters.searchText, filters.dateRange)
                }}
                onChange={(e: any) => {
                  setFilters({
                    ...filters,
                    searchText: e.target.value,
                  })

                  if (!e.target.value) {

                    applyAllFilters('', filters.dateRange)
                  }

                }}
                style={{ width: '400px' }}
                allowClear
              />
            </Col>
            <Col>
              <Input
                type='daterange'
                value={filters.dateRange}
                onChange={(e: any, date: [string, string]) => {
                  console.log('date', date, e)
                  applyAllFilters(filters.searchText, date)
                  setFilters((prev: any) => ({
                    ...prev,
                    dateRange: e,
                  }))
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col style={{ display: 'flex' }}>
          <Row align="bottom">
            <Row style={{ marginRight: '1.5rem', display: 'flex', alignItems: 'center' }}>
              <ExportButton
                fileName='Visitor Logs Data'
                exportData={exportedData}
              />
            </Row>
            <ColumnSelectButton
              selectedColumns={selectedTableColumns}
              columnOptions={selectColumnOptions}
              updateSelectedColumns={(values: string[]) => setSelectedTableColumns(values)}
            />
          </Row>
        </Col>
      </Row>
      <AdiTable
        scroll={{ x: '100%', y: '50vh' }}
        loading={isLoading}
        columns={finalTableColumns}
        tableData={filters.filteredData}
        rowSelection={{ ...rowSelection }}
        fullWidth
        marginTop='20px'
      />
    </>
  )
}

export default Visitors

const tableColumns = [
  {
    title: formatTitle('Status'),
    dataIndex: 'status',
    key: 'status',
    ...TABLE_CELL_CONFIG,
    width: 200,
    filters: [
      { text: 'Checked-In', value: VISITOR_STATUS.CHECKED_IN },
      { text: 'Checked-Out', value: VISITOR_STATUS.CHECKED_OUT },
    ],
    onFilter: (value: string, record: any) => record.status === value,
    render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
  },
  {
    title: formatTitle('Invitation'),
    dataIndex: 'invitation',
    key: 'invitation',
    ...TABLE_CELL_CONFIG,
    width: 200,
    render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
  },
  {
    title: formatTitle('Date'),
    dataIndex: 'visit_date',
    key: 'visit_date',
    ...TABLE_CELL_CONFIG,
    sorter: (a: any, b: any) => a.visit_date?.localeCompare(b.visit_date),
    render: (text: string) => text ? moment(text).format('DD/MM/YYYY') : initialCellValue(text),
  },
  {
    title: formatTitle('Company'),
    dataIndex: 'company',
    key: 'company',
    ...TABLE_CELL_CONFIG,
    render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
  },
  {
    title: formatTitle('Visitor Name'),
    dataIndex: 'first_name',
    key: 'first_name',
    ...TABLE_CELL_CONFIG,
    render: (text: string, record: any) => {
      const { first_name, last_name } = record
      return first_name && last_name ? capitaliseFirst(`${first_name} ${last_name}`) : initialCellValue(text)
    },
  },
];

const visitorColumns = [
  {
    title: formatTitle('Visitor Email'),
    dataIndex: 'email',
    key: 'email',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle('Visitor Phone No.'),
    dataIndex: 'phone_no',
    key: 'phone_no',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle('Mnda Status'),
    dataIndex: 'mnda_status',
    key: 'mnda_status',
    ...TABLE_CELL_CONFIG,
    render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
  },
];

const fixedColumns = [
  {
    title: formatTitle('Host'),
    dataIndex: 'host',
    key: 'host',
    ...TABLE_CELL_CONFIG,
    render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
  },
  {
    title: formatTitle('Check-In'),
    dataIndex: 'check_in_time',
    key: 'check_in_time',
    ...TABLE_CELL_CONFIG,
    width: 200,
    render: (text: string) => text ? moment(text).format('HH:mm') : initialCellValue(text),
  },
  {
    title: formatTitle('Check-Out'),
    dataIndex: 'check_out_time',
    key: 'check_out_time',
    ...TABLE_CELL_CONFIG,
    width: 200,
    render: (text: string) => text ? moment(text).format('HH:mm') : initialCellValue(text),
  },
];

const extraColumns = [
  {
    title: formatTitle('Background Check'),
    dataIndex: 'background_check',
    key: 'background_check',
    ...TABLE_CELL_CONFIG,
    render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
  }
];


