/**
 * @description This is a custom hook that handles form data and APIs in LogCall, LogEmail and LogMeeting
 * @version 1.0.0
 * @author Bruce Zhu 
 * @date 2023-02-18
 */

import { useReducer, useEffect } from 'react'
import { message } from 'antd'
import { ContactType } from '../statics/types'
import moment from 'moment'
import { ADI_CONFIRM, combineDateAndTime } from 'utilities/Functions/GlobalHelperFunctions'
import { IMeetingPayload, IRecordPayload } from '../statics/types'
import { ContactAPI, RecordAPI, SyncEmailAPI, UserAPI } from '../apis';
import { useGeneralStore, useUserStore } from 'zustand-stores'

/* ------------------------------ Constants ------------------------------ */

const emptyEmailPayload = {
  record_ID: '',
  attendies_list: { users: [], names: [] },
  business_ID: '',
  contact_ID: [],
  contact_through: ContactType.Email,
  contents: '',
  created_by: '',
  datetime: '',
  subject: '',
  void: false,
}
const emptyMeetingPayload = {
  record_ID: '',
  attendies_list: { users: [], names: [] },
  business_ID: '',
  contact_ID: [],
  contact_through: ContactType.Meeting,
  contents: '',
  created_by: '',
  datetime: '',
  duration: '',
  subject: '',
  void: false,
  meeting_actions: []
}

const emptyCallPayload = {
  record_ID: '',
  attendies_list: { users: [], names: [] },
  business_ID: '',
  contact_ID: [],
  contact_through: ContactType.Call,
  contents: '',
  created_by: '',
  datetime: '',
  duration: '',
  subject: '',
  void: false,
}


export const ReducerType = {
  SET_STATE: 'SET_STATE',
  EMPTY_PAYLOAD: 'EMPTY_PAYLOAD',
  INIT_EDIT_MEETING: 'INIT_EDIT_MEETING',
  INIT_EDIT_CALL: 'INIT_EDIT_CALL',
  SBMIT_MEETING: 'INIT_SBMIT_MEETING',
  SBMIT_CALL: 'INIT_SBMIT_CALL',
  SBMIT_EMAIL: 'INIT_SBMIT_EMAIL',
}

/* ------------------------------ Reducer ------------------------------ */

type OptionFormat = { label: string, value: string }[]
type OptionGroupFormat = { label: string, options: OptionFormat }[]

type AttendeeOptionsType = {
  contacts: OptionGroupFormat,
  users: OptionGroupFormat,
  others: OptionGroupFormat,
  fullOptions: OptionGroupFormat,
}

type ReducerState = {
  emailPayload: IRecordPayload;
  meetingPayload: IMeetingPayload;
  callPayload: IRecordPayload;
  attendeeOptions: AttendeeOptionsType;
  record_ID: string;
}

const reducer = (state: ReducerState, action: any) => {
  switch (action.type) {
    case ReducerType.SET_STATE:
      const value = action.payload
      return { ...state, ...value }

    case ReducerType.EMPTY_PAYLOAD:
      return { ...state, emailPayload: emptyEmailPayload, meetingPayload: emptyMeetingPayload, callPayload: emptyCallPayload }

    case ReducerType.SBMIT_EMAIL:
      submitEmail({ state, ...action.payload })
      return { ...state }

    case ReducerType.INIT_EDIT_MEETING:
      initialiseEditMeeting({ state, ...action.payload })
      return { ...state }

    case ReducerType.SBMIT_MEETING:
      submitMeeting({ state, ...action.payload })
      return { ...state }

    case ReducerType.INIT_EDIT_CALL:
      initialiseEditCall({ state, ...action.payload })
      return { ...state }

    case ReducerType.SBMIT_CALL:
      submitCall({ state, ...action.payload })
      return { ...state }

    default:
      return { ...state }
  }
}


/* ------------------------------ Hook ------------------------------ */

const useRecordForm = (object: 'company' | 'contact', objectId: string) => {

  const [state, dispatch] = useReducer(reducer, {
    emailPayload: emptyEmailPayload,
    meetingPayload: emptyMeetingPayload,
    callPayload: emptyCallPayload,
    attendeeOptions: {
      contacts: [{
        label: '',
        options: []
      }],
      users: [{
        label: '',
        options: []
      }],
      others: [{
        label: '',
        options: []
      }],
      fullOptions: [{
        label: '',
        options: []
      }]
    },
    record_ID: ''   // for some reason, record_ID in the payload objects above will be overwritten to empty string, so add this attribute here and handle separately
  })

  // get contact/attendee options for form opened
  useEffect(() => {

    const getAttendeeOptions = async () => {
      var attendeeOptions: AttendeeOptionsType = {
        contacts: [],
        users: [],
        others: [],
        fullOptions: []
      }

      try {

        if (object === 'company') {
          // get associated contacts on company page
          // await ContactAPI.getContactsByBusId(objectId)
          //   .then(contacts => {
          //     attendeeOptions.contacts = contacts.map((contact: any) => {
          //       return { label: contact.full_name, value: contact.contact_ID }
          //     })
          //   })

          // get all contacts and separate into company contacts and other contacts
          await ContactAPI.getAllContacts()
            .then(contacts => {
              let companyContactOptions: OptionFormat = []
              let otherContactOptions: OptionFormat = []
              contacts.map((contact: any) => {
                if (contact.business_ID === objectId) {
                  companyContactOptions.push({ label: contact.full_name, value: contact.contact_ID })
                } else {
                  otherContactOptions.push({ label: contact.full_name, value: contact.contact_ID })
                }
              })
              const contactsOptions: OptionGroupFormat = [
                {
                  label: 'Company Contacts',
                  options: companyContactOptions
                },
                // {
                //   label: 'Other Contacts',
                //   options: otherContactOptions
                // }
              ]
              attendeeOptions.contacts = contactsOptions

            })

        } else {
          // get all contacts oncontact page
          await ContactAPI.getAllContacts()
            .then(contacts => {
              let contactOptions: OptionFormat = []
              attendeeOptions.contacts = contacts.map((contact: any) => {
                contactOptions.push({ label: contact.full_name, value: contact.contact_ID })
              })
              attendeeOptions.contacts = [
                {
                  label: 'Contacts',
                  options: contactOptions
                }
              ]
            })
        }

        // get all users
        let userOptions: OptionFormat = []
        await UserAPI.getAllUsers()
          .then(users => {
            attendeeOptions.users = users.map((user: any) => {
              userOptions.push({ label: user.full_name, value: user.username })
            })
            attendeeOptions.users = [
              {
                label: 'System Users',
                options: userOptions
              }
            ]
          })

      } catch (e) {
        message.error('Failed to get contacts.')
      }

      attendeeOptions.fullOptions = attendeeOptions.contacts.concat(attendeeOptions.users)
      dispatch({ type: ReducerType.SET_STATE, payload: { attendeeOptions } })
    }

    getAttendeeOptions()


  }, [])


  // update full options when others change
  useEffect(() => {
    const fullOptions = state.attendeeOptions.contacts.concat(state.attendeeOptions.users, state.attendeeOptions.others)
    dispatch({ type: ReducerType.SET_STATE, payload: { attendeeOptions: { ...state.attendeeOptions, fullOptions } } })

  }, [state.attendeeOptions.others])


  return [state, dispatch] as const
}


export default useRecordForm


/* ------------------------------ Functions ------------------------------ */


type EmailSubmitParams = {
  state: ReducerState,
  values: any,
  isEdit: boolean,
  businessId?: string,
  onSuccess: Function
}

const submitEmail = async (params: EmailSubmitParams) => {
  const { state, values, isEdit, businessId, onSuccess } = params

  var payload = state.emailPayload
  payload.record_ID = state.record_ID
  convertValuesToPayload(state, values, payload)

  if (businessId) payload.business_ID = businessId
  state.emailPayload = payload

  confirmAndSubmit('email', isEdit, payload, onSuccess)

}




type MeetingEditParams = {
  state: ReducerState,
  recordId: string,
  onSuccess: Function
}

const initialiseEditMeeting = (params: MeetingEditParams) => {
  const { state, recordId, onSuccess } = params

  state.record_ID = recordId
  const key = 'loading'
  // message.loading({ content: 'Pulling meeting data...', key })
  useGeneralStore.getState().setIsGlobalLoading(true)

  RecordAPI.getRecordById(recordId)
    .then(meetingData => {
      state.meetingPayload = meetingData

      const values = convertDataToFormValues(meetingData)
      message.destroy(key)
      onSuccess(values)

    }).catch(e => {
      // console.log('e', e)
      message.error({ content: `Failed to get meeting record.`, key })
    }).finally(() => {
      useGeneralStore.getState().setIsGlobalLoading(false)
    })


}

type MeetingSubmitParams = {
  state: ReducerState,
  values: any,
  businessId: string,
  isEdit: boolean,
  onSuccess: Function
}

const submitMeeting = (params: MeetingSubmitParams) => {
  const { state, values, businessId, isEdit, onSuccess } = params

  // convert values to payload
  var payload = state.meetingPayload
  payload.record_ID = state.record_ID
  convertValuesToPayload(state, values, payload)

  if (businessId) payload.business_ID = businessId
  state.meetingPayload = payload

  confirmAndSubmit('meeting', isEdit, payload, onSuccess)
}

type CallEditParams = {
  state: ReducerState,
  recordId: string,
  onSuccess: Function
}

const initialiseEditCall = async (params: CallEditParams) => {
  const { state, recordId, onSuccess } = params
  const key = 'loading'
  // message.loading({ content: 'Pulling call data...', key })
  useGeneralStore.getState().setIsGlobalLoading(true)

  state.record_ID = recordId
  await RecordAPI.getRecordById(recordId)
    .then(record => {
      state.callPayload = { ...record }

      const values = convertDataToFormValues(record, true)

      // process attendee
      var contact: any = ''
      var selectContact = true
      if (Array.isArray(record.attendies_list.names) && record.attendies_list.names.length > 0) {
        // it's non-contact and non-user
        selectContact = false
        contact = record.attendies_list.names[0]
      } else if (Array.isArray(record.contact_ID) && record.contact_ID.length > 0) {
        // it's a contact, get id
        contact = record.contact_ID[0]
      } else if (Array.isArray(record.attendies_list.users) && record.attendies_list.users.length > 0) {
        // it's a user, get username
        contact = record.attendies_list.users[0]
      }


      values.contact = contact

      message.destroy(key)
      onSuccess(values, selectContact)
    }).catch(e => {
      message.error({ content: `Failed to get call record.`, key })
    }).finally(() => {
      useGeneralStore.getState().setIsGlobalLoading(false)
    })
}

type CallSubmitParams = {
  state: ReducerState,
  values: any,
  businessId: string,
  isEdit: boolean,
  onSuccess: Function
}

const submitCall = (params: CallSubmitParams) => {
  const { state, values, businessId, isEdit, onSuccess } = params
  // convert values to payload
  var payload = state.callPayload
  payload.record_ID = state.record_ID
  convertValuesToPayload(state, values, payload, true)

  if (businessId) payload.business_ID = businessId
  state.callPayload = payload

  confirmAndSubmit('call', isEdit, payload, onSuccess)

}

// convert values to payload
const convertValuesToPayload = (state: ReducerState, values: any, payload: any, isCall?: boolean) => {
  payload.datetime = combineDateAndTime(values.date, values.time)
  payload.subject = values.subject
  payload.contents = values.contents || ''
  // meeting and call have duration
  if (values.duration) payload.duration = moment(values.duration).format('HH:mm:ss')

  // process attendees
  const usernames = state.attendeeOptions.users[0].options.map((user: any) => user.value)
  let contactIds = state.attendeeOptions.contacts[0].options.map((contact: any) => contact.value)
  if (state.attendeeOptions.contacts.length > 1) {
    contactIds = contactIds.concat(state.attendeeOptions.contacts[1].options.map((contact: any) => contact.value))
  }

  var attendies_list: any = { users: [], names: [] }
  var contact_ID: any = []
  if (isCall) {
    // only one attendee
    const att = values.contact
    if (usernames.includes(att)) {
      attendies_list.users.push(att)
    } else if (contactIds.includes(att)) {
      contact_ID.push(att)
    } else {
      attendies_list.names.push(att)
    }
  } else {
    // one or more attendees
    for (let att of values.attendees) {
      if (usernames.includes(att)) {
        attendies_list.users.push(att)
      } else if (contactIds.includes(att)) {
        contact_ID.push(att)
      } else {
        attendies_list.names.push(att)
      }
    }
  }
  payload.attendies_list = attendies_list
  payload.contact_ID = contact_ID

}

// convert record data to form values
const convertDataToFormValues = (data: any, isCall?: boolean) => {
  const values: any = { ...data }
  values.date = moment(values.datetime)
  values.time = moment(values.datetime)
  if (values.duration) {
    values.duration = moment(moment().format('YYYY-MM-DDT') + values.duration)  // to make it a valid moment object
  }
  if (!isCall) {
    // get attendees 
    var attendees: any = []
    for (let user of values.attendies_list.users) {
      attendees.push(user)
    }
    for (let contact of values.contact_ID) {
      attendees.push(contact)
    }
    // TODO: handle non-contact and non-user

    values.attendees = attendees

  }  // call's attendee is handled separately

  return values
}

const confirmAndSubmit = async (type: 'email' | 'call' | 'meeting', isEdit: boolean, payload: any, onSuccess: Function) => {
  const loading = useGeneralStore.getState().isGlobalLoading

  if (loading) {
    // prevent this function from being called twice - this may be a side effect of using store.dispatch below
    return;
  }

  const capType = type.charAt(0).toUpperCase() + type.slice(1)
  const username = useUserStore.getState().username
  if (!payload.created_by) payload.created_by = username

  // console.log('ressssss')
  useGeneralStore.getState().setIsGlobalLoading(true)
  try {
    if (type === 'email') {
      await SyncEmailAPI.logEmail(payload)
    } else {
      if (isEdit) {
        await RecordAPI.updateRecord(payload)
      } else {
        await RecordAPI.createRecord(payload)
      }
    }

    message.success({
      content: `${capType} ${isEdit ? 'updated' : 'logged'} successfully!`,
      duration: 1,
      onClose: () => onSuccess()
    })
  } catch (e) {
    message.error({ content: `Failed to ${isEdit ? 'update' : 'log'} the ${type}.` })
  } finally {
    useGeneralStore.getState().setIsGlobalLoading(false)
  }

}



const getOtherAttendeeOptions = async (state: ReducerState, recordId: string) => {
  var record: any = null

  await RecordAPI.getRecordById(recordId).then(res => record = res)

  if (record === null) return []

  // get attendee options for non-contact and non-user
  const contactsAndUsers = state.attendeeOptions.contacts.concat(state.attendeeOptions.users)
  const names: string[] = contactsAndUsers.map((obj: any) => obj.label)

  // compare with record's attendees
  const others = record.attendies_list?.names.filter((name: string) => !names.includes(name))

  if (Array.isArray(others) && others.length > 0) {
    const otherOptions = others.map((name: string) => { return { label: name, value: name } })
    return otherOptions
  } else {
    return []
  }

}