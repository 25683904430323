import { openDB } from 'idb';

const initDB = async () => {
  const db = await openDB('my-database', 1, {
    upgrade(db) {
      db.createObjectStore('records', { keyPath: 'id' });
    },
  });
  return db;
};

export default initDB;
