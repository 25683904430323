import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { PageWrapper } from 'statics/styles/StyledComponents';
import { SESSION_STORAGE_KEYS } from 'utilities/CONSTANTS';
import Header from '../../Headers/DesktopHeader';
import { getSessionStorage } from 'utilities/Functions/GlobalHelperFunctions';
import { isMobile } from 'react-device-detect';
import { getBizCode } from 'utilities/Functions/GlobalHelperFunctions';


import Loader from 'components/Loading';

const Dashboard = () => {
  const [loading, setLoading] = React.useState(false);
  const [color, setColor] = React.useState('#ffffff');
  const [quickSightUrl, setQuickSightUrl] = React.useState('');
  // this is needed, because InfiniteCalendar forces window scroll
  window.scrollTo(0, 0);
  const script = document.createElement('script');
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const tokenString = getSessionStorage(SESSION_STORAGE_KEYS.IDTOKEN, true)
    if (tokenString) {
      const token = tokenString;
      setIsLoading(true)

      const { jwtToken } = token;
      const bizCode = getBizCode()
      console.log("dashboard bizcode, ", bizCode)
      let module_name = ''
      if (bizCode == 'tco') {
        module_name = 'tco'
      }
      else if (bizCode == 'ittpl' || bizCode == 'papl') {
        module_name = 'mainittpl'
      }
      else if (bizCode == 'acc') {
        module_name = 'acc'
      }
      else {
        module_name = 'main'
      }
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_BASE}itrazo-quicksight/dashboard-embed`,
        // data: { 'id_token': jwtToken, module: 'valueChain' }
        // data: { 'id_token': jwtToken, module: 'main' }
        data: { 'id_token': jwtToken, module: module_name }
      })

        .then((res: any) => {
          console.log("res.toString()", res.data.EmbedUrl);
          setQuickSightUrl(res.data.EmbedUrl);

        })
        .catch(e => {
          console.log(e);
        }).finally(() => {
          setIsLoading(false)
        })
    }

  }, []);
  return (
    <>

      <PageWrapper>
        {
          !isMobile && <Header pageTitle="Dashboard" />
        }
        <Loader show={isLoading}>
          <div style={{ height: "100vh" }}>
            <iframe width="100%" height="93%" src={quickSightUrl}></iframe>
          </div>
        </Loader>
      </PageWrapper>
    </>
  );
};
export default Dashboard;
